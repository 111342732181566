import React, { useState, useEffect } from 'react';
import { CloseCircle, FilterEmpty, FilterFull } from "../components/icons/Icons";
import { useTranslation } from "react-i18next";
import useFetch from "../hooks/useFetch";
import i18n from "../i18n";
import Dropdown from "flowbite/src/components/dropdown";
import Accordion from "../components/Accordion";
import { Link, useLocation } from "react-router-dom";
import { API_URL, HEADER_HEIGHT } from "../config";
import Seo from "../components/Seo";
import translationsES from '../locales/translationES.json';
import translationsEN from '../locales/translationEN.json';
import translationsFR from '../locales/translationFR.json';
import translationsIT from '../locales/translationIT.json';
import translationsPT from '../locales/translationPT.json';
import translationsDE from '../locales/translationDE.json';
import translationsCA from '../locales/translationCA.json';

const nationalityMapping = {
    "de": {
        "amerikanisch": "estadounidense",
        "englisch": "inglesa",
        "japanisch": "japonesa",
        "senegalesisch": "senegalesa",
        "französisch": "francesa"
    },
    "ca": {
        "nord-americà": "estadounidense",
        "anglès": "inglesa",
        "japonesa": "japonesa",
        "senegalesa": "senegalesa",
        "francès": "francesa"
    },
    "en": {
        "american": "estadounidense",
        "english": "inglesa",
        "japanese": "japonesa",
        "senegalese": "senegalesa",
        "french": "francesa"
    },
    "fr": {
        "américaine": "estadounidense",
        "anglaise": "inglesa",
        "japonaise": "japonesa",
        "sénégalaise": "senegalesa",
        "française": "francesa"
    },
    "it": {
        "americana": "estadounidense",
        "inglesa": "inglesa",
        "giapponese": "japonesa",
        "senegalese": "senegalesa",
        "francese": "francesa"
    },
    "pt": {
        "americana": "estadounidense",
        "inglesa": "inglesa",
        "japonesa": "japonesa",
        "senegalesa": "senegalesa",
        "francesa": "francesa"
    }
};

export default function PionerasInformaticas() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, -HEADER_HEIGHT);
    }, [location]);

    const currentLang = i18n.language;

    const translateNationality = (nationality) => {
        return nationalityMapping[currentLang] && nationalityMapping[currentLang][nationality.toLowerCase()]
            ? nationalityMapping[currentLang][nationality.toLowerCase()]
            : nationality;
    };

    let natFiltersParam = new URL(window.location).searchParams.get("nationalities") !== null
        ? new URL(window.location).searchParams.get("nationalities").split(",").map((filterName) => {
            const spanishEquivalent = translateNationality(filterName);
            return [{ type: "nationalities", value: spanishEquivalent }];
        }).flat()
        : [];
    let awardFiltersParam = new URL(window.location).searchParams.get("awards") !== null
        ? new URL(window.location).searchParams.get("awards").split(",").map((filterName) => ([{ type: "awards", value: filterName }])).flat()
        : [];
    let fieldsFiltersParam = new URL(window.location).searchParams.get("fields") !== null
        ? new URL(window.location).searchParams.get("fields").split(",").map((filterName) => ([{ type: "fields", value: filterName }])).flat()
        : [];
    let companiesFiltersParam = new URL(window.location).searchParams.get("companies") !== null
        ? new URL(window.location).searchParams.get("companies").split(",").map((filterName) => ([{ type: "companies", value: filterName }])).flat()
        : [];
    let filters = natFiltersParam.concat(awardFiltersParam).concat(fieldsFiltersParam).concat(companiesFiltersParam);

    const { t } = useTranslation();
    const qs = require('qs');
    const tab = new URL(window.location.href).searchParams.get("tab");
    const [filtersVue, setFiltersVue] = useState(natFiltersParam.concat(awardFiltersParam).concat(fieldsFiltersParam).concat(companiesFiltersParam));
    const [queryAll, setQueryAll] = useState(qs.stringify({
        filters: {
            nationality: {
                value: {
                    $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                }
            },
            awards: {
                value: {
                    $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                }
            },
            fields: {
                value: {
                    $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                }
            },
            companies: {
                value: {
                    $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                }
            },
        },
        sort: ['surname:asc']
    }, {
        encodeValuesOnly: true,
    }));
    const [queryAlphabetic, setQueryAlphabetic] = useState(qs.stringify({
        filters: {
            nationality: {
                value: {
                    $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                }
            },
            awards: {
                value: {
                    $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                }
            },
            fields: {
                value: {
                    $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                }
            },
            companies: {
                value: {
                    $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                }
            },
        }
    }, {
        encodeValuesOnly: true,
    }));
    const [queryChronology, setQueryChronology] = useState(qs.stringify({
        filters: {
            nationality: {
                value: {
                    $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                }
            },
            awards: {
                value: {
                    $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                }
            },
            fields: {
                value: {
                    $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                }
            },
            companies: {
                value: {
                    $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                }
            },
        }
    }, {
        encodeValuesOnly: true,
    }));

    // Update all queries
    const updateQueries = () => {
        setQueryAll(qs.stringify({
            filters: {
                nationality: {
                    value: {
                        $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                    }
                },
                awards: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                    }
                },
                fields: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                    }
                },
                companies: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                    }
                },
            }
        }, {
            encodeValuesOnly: true,
        }));

        setQueryAlphabetic(qs.stringify({
            filters: {
                nationality: {
                    value: {
                        $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                    }
                },
                awards: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                    }
                },
                fields: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                    }
                },
                companies: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                    }
                },
            }
        }, {
            encodeValuesOnly: true,
        }));
        setQueryChronology(qs.stringify({
            filters: {
                nationality: {
                    value: {
                        $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                    }
                },
                awards: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                    }
                },
                fields: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                    }
                },
                companies: {
                    value: {
                        $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                    }
                },
            }
        }, {
            encodeValuesOnly: true,
        }));
    }

    useEffect(() => {
        if (new URL(window.location).searchParams.get("fields") !== null) {
            fieldsFiltersParam = new URL(window.location).searchParams.get("fields") !== null
                ? new URL(window.location).searchParams.get("fields").split(",").map((filterName) => ([{ type: "fields", value: filterName }])).flat()
                : [];
            setFiltersVue([{ type: fieldsFiltersParam[0].type, value: fieldsFiltersParam[0].value }]);
            filters = [{ type: fieldsFiltersParam[0].type, value: fieldsFiltersParam[0].value }];
            updateQueries();
        }
    }, [location]);

    // Add filter parameter in URL
    const addFilterToURLParam = (parameterType, parameter) => {
        var newurl = "";
        const spanishParameter = translateNationality(parameter);
        if (window.location.search.includes(parameterType)) {
            if (!window.location.search.includes(parameter)) {
                let currentParams = new URL(window.location.href).searchParams.get(parameterType);
                newurl = window.location.href.replace((currentParams), (`${currentParams},${spanishParameter}`));
            }
        } else {
            if (new URL(window.location.href).search === "") {
                newurl = window.location.href + '?' + parameterType + '=' + spanishParameter;
            } else {
                newurl = window.location.href + '&' + parameterType + '=' + spanishParameter;
            }
        }
        window.history.pushState({ path: newurl }, '', newurl);
    }

    // Remove filter parameter from URL
    const removeFilterFromURLParam = (parameterType, parameter) => {
        const spanishParameter = translateNationality(parameter);
        if (window.location.search.includes(parameterType)) {
            if (window.location.search.includes(spanishParameter)) {
                let newurl = window.location.href.replace(("," + spanishParameter), "").replace((spanishParameter) + ",", "").replace(("&" + parameterType + "=" + spanishParameter), "").replace(("?" + parameterType + "=" + spanishParameter), "");
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    }

    // Click on remove on filter item
    const handleRemoveFilter = (e) => {
        removeFilterFromURLParam(e.target.dataset.filterType, e.target.value);
        setFiltersVue(filtersVue.filter(filter => filter.value !== e.target.value));
        filters = filters.filter(filter => filter.value !== e.target.value);
        updateQueries();
    }

    // Click on filter button
    const handleFilterButton = (e) => {
        e.target.classList.toggle("active")
        document.getElementById("filter-filters").classList.toggle("hidden")
        Array.from(e.target.children).forEach(filterIcon => {
            filterIcon.classList.toggle("hidden");
        })
    }

    // Function to add one parameter to the URL with the type of this parameter (ex: ?type=param)
    const addOneURLParameter = (parameterType, parameter) => {
        var newurl = "";
        if (window.location.search.includes(parameterType)) {
            let currentParams = new URL(window.location.href).searchParams.get(parameterType)
            newurl = window.location.href.replace((currentParams), (parameter));
        } else {
            if (new URL(window.location.href).search === "") {
                newurl = window.location.href + '?' + parameterType + '=' + parameter;
            } else {
                newurl = window.location.href + '&' + parameterType + '=' + parameter;
            }
        }
        window.history.pushState({ path: newurl }, '', newurl);
    }

    // Handle filters' checkbox click
    const handleCheckboxClick = (e) => {
        if (e.target.checked) { // We add a filter
            addFilterToURLParam(e.target.dataset.filterType, e.target.id);
            setFiltersVue(filtersVue.concat([{ type: e.target.dataset.filterType, value: e.target.id }]));
            filters = filters.concat([{ type: e.target.dataset.filterType, value: e.target.id }]);
        } else { // We remove a filter
            removeFilterFromURLParam(e.target.dataset.filterType, e.target.id);
            setFiltersVue(filtersVue.filter(filter => filter.value !== e.target.id));
            filters = filters.filter(filter => filter.value !== e.target.id);
        }
        updateQueries();
    }

    useEffect(() => {
        //Initiate placement of all, alphabetic and chronology slider
        switch (tab) {
            case "az":
                handleClickButton("alphabetic");
                break;
            case "time":
                handleClickButton("chronology");
                break;
            case "all":
                handleClickButton("all");
                break;
            default:
                break;
        }

        new Dropdown(document.getElementById('dropdownNationalityMenu'), document.getElementById('dropdownNationalityButton'));
        new Dropdown(document.getElementById('dropdownAwardMenu'), document.getElementById('dropdownAwardButton'));
        new Dropdown(document.getElementById('dropdownFieldMenu'), document.getElementById('dropdownFieldButton'));
        new Dropdown(document.getElementById('dropdownCompanyMenu'), document.getElementById('dropdownCompanyButton'));
    }, [new URL(window.location.href).search])

    const handleClickButton = (buttonType) => {
        // Close all interactive tools (all categorie's section)
        document.querySelectorAll(".interactive-tool").forEach(tool => { tool.classList.add("hidden") });
        Array.from(document.getElementById("list-cards").children).forEach(cards => { cards.classList.add("hidden") });

        //Change style of buttons
        Array.from(document.getElementById("filter-buttons").children).forEach(button => {
            if (button.firstChild.id === ("button-" + buttonType)) {
                button.firstChild.classList.add("selected");
            } else {
                button.firstChild.classList.remove("selected");
            }
        })

        switch (buttonType) {
            case "all":
                addOneURLParameter("tab", "all")
                document.getElementById("cards-all").classList.remove("hidden");
                setQueryAll(qs.stringify({
                    filters: {
                        nationality: {
                            value: {
                                $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        awards: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        fields: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        companies: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                            }
                        },
                    }
                }, {
                    encodeValuesOnly: true,
                }))
                break;
            case "alphabetic":
                addOneURLParameter("tab", "az")

                document.getElementById("filter-alphabetic").classList.remove("hidden");
                document.getElementById("cards-alphabetic").classList.remove("hidden");
                const sliderAlphabetic = document.getElementById("slider-alphabetic");
                let indexLetterURL = 0;
                let startWithLetter = "";

                if (sliderAlphabetic.children.length === 0) {
                    document.getElementById("slider-alphabetic").style.transform = `translate3d(0px, 0px, 0px)`
                } else {
                    const letterURL = (new URL(window.location.href).searchParams.get("letter") !== null) ? new URL(window.location.href).searchParams.get("letter") : sliderAlphabetic.children[0].dataset.value;
                    indexLetterURL = (Array.from(sliderAlphabetic.children).findIndex(letter => letter.dataset.value === letterURL) === -1) ? 0 : Array.from(sliderAlphabetic.children).findIndex(letter => letter.dataset.value === letterURL);
                    document.getElementById("slider-alphabetic").style.transform = `translate3d(-${indexLetterURL * 100}px, 0px, 0px)`
                    startWithLetter = sliderAlphabetic.children[indexLetterURL].dataset.value;
                }
                setQueryAlphabetic(qs.stringify({
                    filters: {
                        surname: {
                            $startsWith: `${startWithLetter}`,
                        },
                        nationality: {
                            value: {
                                $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        awards: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        fields: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        companies: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                            }
                        },
                    },
                }, {
                    encodeValuesOnly: true,
                }))

                let down_alphabetic = false;
                let scrollLeft_alphabetic;
                let x_alphabetic = 0;
                let currentScrollLeft_alphabetic = indexLetterURL * 100;
                let indexCurrentLetter = indexLetterURL;

                const sliderStart_alphabetic = (e) => {
                    down_alphabetic = true;
                    scrollLeft_alphabetic = currentScrollLeft_alphabetic;
                    if (e.type === "mousedown") { x_alphabetic = e.clientX }
                    else if (e.type === "touchstart") { x_alphabetic = e.changedTouches[0].clientX; }
                    e.currentTarget.classList.remove("duration-1000");
                }
                const sliderMove_alphabetic = (e) => {
                    if (down_alphabetic) {
                        const maxSlide = ((parseInt(e.currentTarget.childElementCount, 10) - 1) * e.currentTarget.dataset.space) //maxSlide = number of letter * 100 (width)
                        const clientX = (e.type === "mousemove") ? e.clientX : e.changedTouches[0].clientX;

                        if (-(scrollLeft_alphabetic + x_alphabetic - clientX) > 0) { currentScrollLeft_alphabetic = 0; }
                        else if (-(scrollLeft_alphabetic + x_alphabetic - clientX) < -maxSlide) { currentScrollLeft_alphabetic = maxSlide; }
                        else { currentScrollLeft_alphabetic = scrollLeft_alphabetic + x_alphabetic - clientX }
                        e.currentTarget.style.transform = "translate3d(" + -(currentScrollLeft_alphabetic) + "px, 0px, 0px)"
                    }
                }
                const sliderEnd_alphabetic = (e) => {
                    down_alphabetic = false;
                    e.currentTarget.classList.add("duration-1000");
                    e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_alphabetic / e.currentTarget.dataset.space) * e.currentTarget.dataset.space) + "px, 0px, 0px)"
                    document.documentElement.classList.remove("overflow-hidden")
                    currentScrollLeft_alphabetic = (currentScrollLeft_alphabetic % 100 < 50) ? currentScrollLeft_alphabetic - (currentScrollLeft_alphabetic % 100) : currentScrollLeft_alphabetic + (100 - currentScrollLeft_alphabetic % 100);

                    indexCurrentLetter = (Math.round(currentScrollLeft_alphabetic / e.currentTarget.dataset.space) * e.currentTarget.dataset.space) / 100;
                    addOneURLParameter("letter", sliderAlphabetic.children[indexCurrentLetter].dataset.value)
                    setTimeout(() => {
                        setQueryAlphabetic(qs.stringify({
                            filters: {
                                surname: {
                                    $startsWith: sliderAlphabetic.children[indexCurrentLetter].dataset.value,
                                },
                                nationality: {
                                    value: {
                                        $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                                awards: {
                                    value: {
                                        $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                                fields: {
                                    value: {
                                        $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                                companies: {
                                    value: {
                                        $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                            },
                        }, {
                            encodeValuesOnly: true,
                        }))
                    }, 1000);

                }
                const sliderLeave_alphabetic = (e) => {
                    if (down_alphabetic) {
                        sliderEnd_alphabetic(e);
                    } else {
                        down_alphabetic = false;
                        e.currentTarget.classList.add("duration-1000");
                        e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_alphabetic / e.currentTarget.dataset.space) * e.currentTarget.dataset.space) + "px, 0px, 0px)"
                        document.documentElement.classList.remove("overflow-hidden")
                    }
                }

                document.getElementById("slider-alphabetic").addEventListener('mousedown', sliderStart_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('mousemove', sliderMove_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('mouseup', sliderEnd_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('mouseleave', sliderLeave_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('touchstart', sliderStart_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('touchmove', sliderMove_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('touchend', sliderEnd_alphabetic)

                break;
            case "chronology":
                addOneURLParameter("tab", "time");

                document.getElementById("filter-chronology").classList.remove("hidden");
                document.getElementById("cards-chronology").classList.remove("hidden");

                const sliderChronology = document.getElementById("slider-chronology");
                let indexTimeURL = 0;
                let dateBirthStart = "1800-01-01";
                let dateBirthEnd = `${new Date().getFullYear()}-12-31`;

                if (sliderChronology.children.length === 0) {
                    document.getElementById("slider-alphabetic").style.transform = `translate3d(0px, 0px, 0px)`
                } else {
                    const timeURL = (new URL(window.location.href).searchParams.get("date") !== null) ? new URL(window.location.href).searchParams.get("date") : sliderChronology.children[0].dataset.value;
                    indexTimeURL = (Array.from(sliderChronology.children).findIndex(time => time.dataset.value === timeURL) === -1) ? 0 : Array.from(sliderChronology.children).findIndex(time => time.dataset.value === timeURL);
                    document.getElementById("slider-chronology").style.transform = `translate3d(-${indexTimeURL * 116}px, 0px, 0px)`
                    dateBirthStart = `${sliderChronology.children[indexTimeURL].dataset.value}-01-01`;
                    dateBirthEnd = `${indexTimeURL === sliderChronology.children.length - 1 ? new Date().getFullYear() : sliderChronology.children[indexTimeURL + 1].dataset.value}-12-31`;
                }
                setQueryChronology(qs.stringify({
                    filters: {
                        $and: [
                            {
                                dateBirth: {
                                    $gte: dateBirthStart,
                                },
                            },
                            {
                                dateBirth: {
                                    $lt: dateBirthEnd,
                                },
                            },
                        ],
                        nationality: {
                            value: {
                                $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        awards: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        fields: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                            }
                        },
                        companies: {
                            value: {
                                $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                            }
                        },
                    },
                }, {
                    encodeValuesOnly: true,
                }))

                let down_chronology = false;
                let scrollLeft_chronology;
                let x_chronology = 0;
                let currentScrollLeft_chronology = indexTimeURL * 116;
                let indexCurrentTime = indexTimeURL;

                const sliderStart_chronology = (e) => {
                    down_chronology = true;
                    scrollLeft_chronology = currentScrollLeft_chronology;
                    if (e.type === "mousedown") { x_chronology = e.clientX }
                    else if (e.type === "touchstart") { x_chronology = e.changedTouches[0].clientX; }
                    e.currentTarget.classList.remove("duration-1000");
                }
                const sliderMove_chronology = (e) => {
                    if (down_chronology) {
                        const maxSlide = ((parseInt(e.currentTarget.childElementCount, 10) - 1) * e.currentTarget.dataset.space) //maxSlide = number of letter * 100 (width)
                        const clientX = (e.type === "mousemove") ? e.clientX : e.changedTouches[0].clientX;

                        if (-(scrollLeft_chronology + x_chronology - clientX) > 0) { currentScrollLeft_chronology = 0; }
                        else if (-(scrollLeft_chronology + x_chronology - clientX) < -maxSlide) { currentScrollLeft_chronology = maxSlide; }
                        else { currentScrollLeft_chronology = scrollLeft_chronology + x_chronology - clientX }
                        e.currentTarget.style.transform = "translate3d(" + -(currentScrollLeft_chronology) + "px, 0px, 0px)"
                    }
                }
                const sliderEnd_chronology = (e) => {
                    down_chronology = false;
                    e.currentTarget.classList.add("duration-1000");
                    e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_chronology / e.currentTarget.dataset.space) * e.currentTarget.dataset.space) + "px, 0px, 0px)"
                    document.documentElement.classList.remove("overflow-hidden")
                    currentScrollLeft_chronology = (currentScrollLeft_chronology % 116 < 50) ? currentScrollLeft_chronology - (currentScrollLeft_chronology % 116) : currentScrollLeft_chronology + (116 - currentScrollLeft_chronology % 116);

                    indexCurrentTime = (Math.round(currentScrollLeft_chronology / e.currentTarget.dataset.space) * e.currentTarget.dataset.space) / 116;
                    addOneURLParameter("date", sliderChronology.children[indexCurrentTime].dataset.value)

                    setTimeout(() => {
                        setQueryChronology(qs.stringify({
                            filters: {
                                $and: [
                                    {
                                        dateBirth: {
                                            $gte: `${sliderChronology.children[indexCurrentTime].dataset.value}-01-01`,
                                        },
                                    },
                                    {
                                        dateBirth: {
                                            $lt: `${(indexCurrentTime + 1 === sliderChronology.children.length) ? `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}` : `${sliderChronology.children[indexCurrentTime + 1].dataset.value}-12-31`}`,
                                        },
                                    },
                                ],
                                nationality: {
                                    value: {
                                        $eq: filters.filter(filter => filter.type === "nationalities").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                                awards: {
                                    value: {
                                        $contains: filters.filter(filter => filter.type === "awards").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                                fields: {
                                    value: {
                                        $contains: filters.filter(filter => filter.type === "fields").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                                companies: {
                                    value: {
                                        $contains: filters.filter(filter => filter.type === "companies").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                            },
                        }, {
                            encodeValuesOnly: true,
                        }))
                    }, 1000);

                }
                const sliderLeave_chronology = (e) => {
                    if (down_chronology) {
                        sliderEnd_chronology(e);
                    } else {
                        down_chronology = false;
                        e.currentTarget.classList.add("duration-1000");
                        e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_chronology / e.currentTarget.dataset.space) * e.currentTarget.dataset.space) + "px, 0px, 0px)"
                        document.documentElement.classList.remove("overflow-hidden")
                    }
                }

                document.getElementById("slider-chronology").addEventListener('mousedown', sliderStart_chronology)
                document.getElementById("slider-chronology").addEventListener('mousemove', sliderMove_chronology)
                document.getElementById("slider-chronology").addEventListener('mouseup', sliderEnd_chronology)
                document.getElementById("slider-chronology").addEventListener('mouseleave', sliderLeave_chronology)
                document.getElementById("slider-chronology").addEventListener('touchstart', sliderStart_chronology)
                document.getElementById("slider-chronology").addEventListener('touchmove', sliderMove_chronology)
                document.getElementById("slider-chronology").addEventListener('touchend', sliderEnd_chronology)

                break;
            default:
                break;
        }
    }

    const fetchPioneers = useFetch(`/api/pioneras?${queryAll}&populate=*`);
    const fetchPioneersAlphabetic = useFetch(`/api/pioneras?${queryAlphabetic}&populate=*`);
    const fetchPioneersChronology = useFetch(`/api/pioneras?${queryChronology}&populate=*`);
    const fetchNationalities = useFetch(`/api/nationalities`, 0);
    const fetchAwards = useFetch(`/api/awards`, 0);
    const fetchFields = useFetch(`/api/fields`, 0);
    const fetchCompanies = useFetch(`/api/companies`, 0);

    useEffect(() => {
        const accordionItems = [
            {
                id: 'accordion-about-exhibition-heading',
                triggerEl: document.querySelector('#accordion-about-exhibition-heading'),
                targetEl: document.querySelector('#accordion-about-exhibition-body'),
                active: false
            }
        ];
        const options = {
            alwaysOpen: false,
            activeClasses: "bg-neutral-100 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100",
            inactiveClasses: "text-neutral-500 dark:text-neutral-400",
            onOpen: (item) => {
                document.querySelector("#accordion-about-exhibition-heading button").classList.add("border-b-0", "rounded-b-none")
                document.querySelector("#accordion-about-exhibition-icon").classList.add('rotate-180')
            },
            onClose: (item) => {
                document.querySelector("#accordion-about-exhibition-heading button").classList.remove("border-b-0", "rounded-b-none")
                document.querySelector("#accordion-about-exhibition-icon").classList.remove('rotate-180')
            },
            onToggle: (item) => {
            }
        };
        new Accordion(accordionItems, options);
    }, [])

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${t("Pioneras_informaticas")}`}
                metaTitle={``}
                metaDescription={`Meta descripcion de la pagina Pioneras Informaticas`}
                metaImage={``}
                keywords={``}
                metaRobots={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/${t("pioneras_informaticas_url")}`}
            />
            <div className="h-fit w-full bg-gradient-to-r from-gradient-50/50 to-gradient-900/50 mt-header-height py-4 px-4 4flex items-center justify-center">
                <h1 className="text-center animation-shine font-bold leading-normal">{t("Pioneras_informaticas")}</h1>
            </div>
            <section className="section !px-0 !py-0" />
            <section className="section bg-neutral-50 dark:bg-neutral-900">
                <div className="my-10">
                    <h2 id="accordion-about-exhibition-heading">
                        <button type="button" className="flex justify-between items-center p-5 w-full font-medium text-left text-neutral-500 rounded-xl border border-neutral-200 dark:border-neutral-700 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800" aria-expanded="false" aria-controls="accordion-about-exhibition-body" data-accordion-target="#accordion-about-exhibition-body" >
                            <span>{t("Todo_sobre_la_exposicion")}</span>
                            <svg id="accordion-about-exhibition-icon" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </h2>
                    <div id="accordion-about-exhibition-body" className="hidden" aria-labelledby="accordion-about-exhibition-heading">
                        <div className="p-5 border border-b-xl border-neutral-200 dark:border-neutral-700 dark:bg-neutral-700 text-neutral-600 dark:text-neutral-300">
                            {(() => {
                                if (i18n.language === "es") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsES.exposicion_detallada }} />
                                        </>
                                    )
                                } else if (i18n.language === "en") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsEN.exposicion_detallada }} />
                                        </>
                                    )
                                }
                                else if (i18n.language === "fr") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsFR.exposicion_detallada }} />
                                        </>
                                    )
                                } else if (i18n.language === "pt") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsPT.exposicion_detallada }} />
                                        </>
                                    )
                                } else if (i18n.language === "it") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsIT.exposicion_detallada }} />
                                        </>
                                    )
                                } else if (i18n.language === "de") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsDE.exposicion_detallada }} />
                                        </>
                                    )
                                } else if (i18n.language === "ca") {
                                    return (
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: translationsCA.exposicion_detallada }} />
                                        </>
                                    )
                                }

                            })()}
                        </div>
                    </div>
                </div>
                <div className="relative pt-[80px] pb-[40px] bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-300 px-4 xs:px-10 sm:px-30 md:px-30 2xl:px-40 pt-10">

                    <div className="flex items-center justify-center">
                        <ul id="filter-buttons" className="flex flex-row flex-wrap text-neutral-200 w-fit justify-center items-center">
                            <li className="w-fit">
                                <button id="button-all" className={`mb-5 mx-5 w-fit px-5 py-3 bg-gradient-to-r from-gradient-50 to-gradient-200 rounded-md font-bold shadow-lg duration-200 hover:scale-105 ${(tab === "all" || tab === null) ? "selected" : ""} disabled:opacity-40`} disabled={fetchPioneers.loading} onClick={() => handleClickButton("all")}>{t("Todas")}</button>
                            </li>
                            <li className="w-fit">
                                <button id="button-alphabetic" className={`mb-5 mx-5 w-fit px-5 py-3 bg-gradient-to-r from-gradient-200 to-gradient-400 rounded-md font-bold shadow-lg duration-200 hover:scale-105 ${(tab === "az") ? "selected" : ""} disabled:opacity-40`} disabled={fetchPioneers.loading} onClick={() => handleClickButton("alphabetic")}>A - Z</button>
                            </li>
                            <li className="w-fit">
                                <button id="button-chronology" className={`mb-5 mx-5 w-fit px-5 py-3 bg-gradient-to-r from-gradient-400 to-gradient-700 rounded-md font-bold shadow-lg duration-200 hover:scale-105 ${(tab === "time") ? "selected" : ""} disabled:opacity-40`} disabled={fetchPioneers.loading} onClick={() => handleClickButton("chronology")}>{t("Cronologia")}</button>
                            </li>
                            <li className="w-fit">
                                <button id="button-filters" className={`mb-5 mx-5 w-fit duration-200 hover:scale-105 flex items-start`} onClick={handleFilterButton}>
                                    <FilterEmpty className="h-10 w-10 fill-gradient-600/80 pointer-events-none" id="filter-empty" />
                                    <FilterFull className="h-10 w-10 fill-gradient-600/80 hidden pointer-events-none" id="filter-full" />
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full mb-3">
                        <span className="hidden" id="filter-filters">
                            <div className="mb-5 flex items-center justify-center">
                                <ul className="flex flex-row flex-wrap text-neutral-200 w-fit justify-center">
                                    <li className="w-fit">
                                        <button id="dropdownNationalityButton" className="font-bold text-neutral-800 dark:text-neutral-200 bg-neutral-300 dark:bg-neutral-700 hover:opacity-80 rounded-md px-5 py-3 text-center inline-flex items-center mb-5 mx-3" type="button">
                                            {t("Nacionalidades")}
                                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </button>
                                        <div id="dropdownNationalityMenu" className="z-1000 hidden bg-neutral-200 divide-y divide-neutral-100 rounded shadow dark:bg-neutral-700 z-20 min-w-fit w-56 max-h-60 overflow-scroll">
                                            <ul className="text-neutral-700 dark:text-neutral-200 px-5 py-5 space-y-3" aria-labelledby="dropdownNationalityButton">
                                                {(() => {
                                                    if (fetchNationalities.loading) {
                                                        return (
                                                            <div className="w-full flex items-center justify-center">
                                                                <svg role="status" className="w-8 h-8 mr-2 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                </svg>
                                                            </div>
                                                        )

                                                    } else if (fetchNationalities.data) {
                                                        return (
                                                            fetchNationalities.data.map((nationality, index) =>
                                                                <li key={index}>
                                                                    <div className="flex items-center cursor-pointer group">
                                                                        <input id={nationality.attributes.value} data-filter-type="nationalities" type="checkbox" value={nationality.attributes.name} className="w-4 h-4 text-gradient-600 bg-neutral-100 rounded border-neutral-300 dark:bg-neutral-700 dark:border-neutral-600 cursor-pointer focus:outline-gradient-500" onChange={handleCheckboxClick} checked={(new URL(window.location).searchParams.get("nationalities") !== null) ? new URL(window.location).searchParams.get("nationalities").split(",").indexOf(nationality.attributes.value) !== -1 : false} />
                                                                        <label htmlFor={nationality.attributes.value} className="w-full ml-2 text-neutral-900 dark:text-neutral-300 cursor-pointer group-hover:font-bold">{nationality.attributes.name}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="w-fit">
                                        <button id="dropdownAwardButton" className="font-bold text-neutral-800 dark:text-neutral-200 bg-neutral-300 dark:bg-neutral-700 hover:opacity-80 rounded-md px-5 py-3 text-center inline-flex items-center mb-5 mx-3" type="button">
                                            {t("Logros")}
                                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </button>
                                        <div id="dropdownAwardMenu" className="z-1000 hidden bg-neutral-200 divide-y divide-neutral-100 rounded shadow w-44 dark:bg-neutral-700 z-20 w-screen xs:w-[300px] sm:w-[400px] max-h-60 overflow-scroll">
                                            <ul className="text-neutral-700 dark:text-neutral-200 px-5 py-5 space-y-3 w-full" aria-labelledby="dropdownAwardButton">
                                                {(() => {
                                                    if (fetchAwards.loading) {
                                                        return (
                                                            <div className="w-full flex items-center justify-center">
                                                                <svg role="status" className="w-8 h-8 mr-2 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                </svg>
                                                            </div>
                                                        )

                                                    } else if (fetchAwards.data) {
                                                        return (
                                                            fetchAwards.data.map((award, index) =>
                                                                <li key={index}>
                                                                    <div className="flex items-center cursor-pointer group">
                                                                        <input id={award.attributes.value} data-filter-type="awards" type="checkbox" value={award.attributes.name} className="w-4 h-4 text-gradient-600 bg-neutral-100 rounded border-neutral-300 dark:bg-neutral-700 dark:border-neutral-600 cursor-pointer focus:outline-gradient-500" onChange={handleCheckboxClick} checked={(new URL(window.location).searchParams.get("awards") !== null) ? new URL(window.location).searchParams.get("awards").split(",").indexOf(award.attributes.value) !== -1 : false} />
                                                                        <label htmlFor={award.attributes.value} className="w-full ml-2 text-neutral-900 dark:text-neutral-300 cursor-pointer group-hover:font-bold">{award.attributes.name}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="w-fit">
                                        <button id="dropdownFieldButton" className="font-bold text-neutral-800 dark:text-neutral-200 bg-neutral-300 dark:bg-neutral-700 hover:opacity-80 rounded-md px-5 py-3 text-center inline-flex items-center mb-5 mx-3" type="button">
                                            {t("Areas")}
                                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </button>
                                        <div id="dropdownFieldMenu" className="z-1000 hidden bg-neutral-200 divide-y divide-neutral-100 rounded shadow w-44 dark:bg-neutral-700 z-20 w-screen xs:w-[300px] sm:w-[400px] max-h-60 overflow-scroll">
                                            <ul className="text-neutral-700 dark:text-neutral-200 px-5 py-5 space-y-3 w-full" aria-labelledby="dropdownFieldButton">
                                                {(() => {
                                                    if (fetchFields.loading) {
                                                        return (
                                                            <div className="w-full flex items-center justify-center">
                                                                <svg role="status" className="w-8 h-8 mr-2 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                </svg>
                                                            </div>
                                                        )

                                                    } else if (fetchFields.data) {
                                                        return (
                                                            fetchFields.data.map((field, index) =>
                                                                <li key={index}>
                                                                    <div className="flex items-center cursor-pointer group">
                                                                        <input id={field.attributes.value} data-filter-type="fields" type="checkbox" value={field.attributes.name} className="w-4 h-4 text-gradient-600 bg-neutral-100 rounded border-neutral-300 dark:bg-neutral-700 dark:border-neutral-600 cursor-pointer focus:outline-gradient-500" onChange={handleCheckboxClick} checked={(new URL(window.location).searchParams.get("fields") !== null) ? new URL(window.location).searchParams.get("fields").split(",").indexOf(field.attributes.value) !== -1 : false} />
                                                                        <label htmlFor={field.attributes.value} className="w-full ml-2 text-neutral-900 dark:text-neutral-300 cursor-pointer group-hover:font-bold">{field.attributes.name}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="w-fit">
                                        <button id="dropdownCompanyButton" className="font-bold text-neutral-800 dark:text-neutral-200 bg-neutral-300 dark:bg-neutral-700 hover:opacity-80 rounded-md px-5 py-3 text-center inline-flex items-center mb-5 mx-3" type="button">
                                            {t("Empresas")}
                                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </button>
                                        <div id="dropdownCompanyMenu" className="z-1000 hidden bg-neutral-200 divide-y divide-neutral-100 rounded shadow w-44 dark:bg-neutral-700 z-20 w-screen xs:w-[300px] sm:w-[400px] max-h-60 overflow-scroll">
                                            <ul className="text-neutral-700 dark:text-neutral-200 px-5 py-5 space-y-3 w-full" aria-labelledby="dropdownFieldButton">
                                                {(() => {
                                                    if (fetchCompanies.loading) {
                                                        return (
                                                            <div className="w-full flex items-center justify-center">
                                                                <svg role="status" className="w-8 h-8 mr-2 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                </svg>
                                                            </div>
                                                        )

                                                    } else if (fetchCompanies.data) {
                                                        return (
                                                            fetchCompanies.data.map((company, index) =>
                                                                <li key={index}>
                                                                    <div className="flex items-center cursor-pointer group">
                                                                        <input id={company.attributes.value} data-filter-type="companies" type="checkbox" value={company.attributes.name} className="w-4 h-4 text-gradient-600 bg-neutral-100 rounded border-neutral-300 dark:bg-neutral-700 dark:border-neutral-600 cursor-pointer focus:outline-gradient-500" onChange={handleCheckboxClick} checked={(new URL(window.location).searchParams.get("companies") !== null) ? new URL(window.location).searchParams.get("companies").split(",").indexOf(company.attributes.value) !== -1 : false} />
                                                                        <label htmlFor={company.attributes.value} className="w-full ml-2 text-neutral-900 dark:text-neutral-300 cursor-pointer group-hover:font-bold">{company.attributes.name}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </span>

                        <span className={`interactive-tool ${(tab === "az") ? "" : "hidden"}`} id="filter-alphabetic">
                            <div className="box-content h-[60px] overflow-hidden relative select-none mb-4">
                                <ul id="slider-alphabetic" className="inline-flex h-[60px] -left-[50px] py-0 px-[50%] relative text-center whitespace-nowrap z-2"
                                    data-space="100"
                                >
                                    {(() => {
                                        if (fetchPioneers.data !== null) {

                                            const allLetters = [...new Set(fetchPioneers.data.map((pioneer) => (pioneer.attributes.surname.slice(0, 1))).sort())];
                                            return (
                                                allLetters.map((letter, index) =>
                                                    <li key={index} className="cursor-pointer items-center justify-center inline-flex h-[60px] outline-0 overflow-hidden overflow-ellipsis w-[100px] box-border" aria-label={letter} data-value={letter} >
                                                        {letter}
                                                    </li>
                                                )
                                            )
                                        }
                                    })()}
                                </ul>
                                <div className="bg-neutral-300/80 dark:bg-neutral-600/80 rounded-[50%] h-[50px] mt-[5px] -ml-[25px] w-[50px] left-[50%] absolute top-0 z-0" />
                            </div>
                        </span>
                        <span className={`interactive-tool ${(tab === "time") ? "" : "hidden"}`} id="filter-chronology">
                            <div className="box-content h-[60px] overflow-hidden relative select-none mb-4">
                                <ul id="slider-chronology" className="h-full -left-[58px] inline-block py-0 px-[50%] relative text-center whitespace-nowrap z-2"
                                    data-space="116"
                                >
                                    {(() => {
                                        if (fetchPioneers.data) {

                                            const dateRanges = (year, rule, sum = 0) => Math.floor(year / rule) * rule + sum
                                            const allDates = [...new Set(fetchPioneers.data.map((pioneer) => (dateRanges(new Date(pioneer.attributes.dateBirth).getFullYear(), 10))).sort(function (a, b) { return a - b; }))];
                                            return (
                                                allDates.map((date, index) =>
                                                    <li key={index}
                                                        className={`border-l-2 border-neutral-800 text-neutral-800 dark:border-neutral-200 dark:text-neutral-200 h-[16px] leading-[22px] overflow-visible relative w-[116px] items-center justify-center cursor-pointer inline-flex outline-0 text-ellipsis mt-[26px] ${(index === allDates.length - 1) ? "border-r-2 " : ""}`}
                                                        aria-label={date}
                                                        data-value={date}
                                                    >
                                                        <span className="text-neutral-800 dark:text-neutral-200 -left-[50%] relative inline-block h-[32px] max-w-full outline-0 overflow-hidden text-ellipsis -top-[22px] whitespace-nowrap">{date}</span>
                                                        {(index === allDates.length - 1) ? (
                                                            <span className="text-neutral-800 dark:text-neutral-200 absolute -right-[50%] w-full -top-[30px] h-[32px]">{t("Presente")}</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </li>
                                                )
                                            )
                                        }
                                    })()}
                                </ul>
                                <div className="border-b-8 border-b-neutral-900 dark:border-b-neutral-50 border-l-8 border-l-transparent border-r-8 border-r-transparent bottom-[8px] block h-0 -ml-[7px] top-auto w-0 z-2 relative left-[50%]" />
                            </div>
                        </span>
                        <div className="flex flex-row items-center">
                            <span>{t("filtros")}: </span>
                            <ul className="flex flex-row flex-wrap">
                                {filtersVue.map((filter, index) =>
                                    <li key={index} className="bg-gradient-500/80 dark:bg-gradient-600/40 px-4 py-2 rounded-full flex flex-row items-center mx-2 text-neutral-200 dark:text-neutral-200 font-bold my-1.5 capitalize">
                                        {t(filter.value.charAt(0).toUpperCase() + filter.value.slice(1)).split("_").join(" ")}
                                        <button className="cursor-pointer group" value={filter.value} data-filter-type={filter.type} onClick={handleRemoveFilter}>
                                            <CloseCircle className="h-5 w-5 ml-1.5 fill-neutral-200 dark:fill-gradient-600 group-hover:rotate-90 transition-transform duration-700 pointer-events-none" />
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div id="list-cards" className="w-full background-fancy-border">

                        <div id="cards-all" className="flex flex-row flex-wrap">
                            {(() => {
                                if (fetchPioneers.loading) {
                                    return (
                                        <>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-0">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-0">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                        </>
                                    )
                                } else {
                                    if (fetchPioneers.data && fetchPioneers.data.length > 0) {
                                        return (
                                            fetchPioneers.data.map((pioneer, index) =>
                                                <div key={index} className={`group relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square mt-4 mr-0 shadow-lg rounded-2xl group-hover:-translate-y-1 duration-200 ${(index + 1) % 2 === 0 ? "md:mr-0" : "md:mr-4"} ${(index + 1) % 3 === 0 ? "xl:mr-0" : "xl:mr-4"}`}>
                                                    <Link to={`/${i18n.language}/${t("pioneras_informaticas_url")}/${pioneer.attributes.Slug}`} className="bg-neutral-300 dark:bg-neutral-600">
                                                        <img className="absolute object-cover top-0 left-0 h-full w-full z-1 rounded-2xl" src={pioneer.attributes.headerCover ? API_URL + (pioneer.attributes.headerCover.data.attributes.formats.medium ? pioneer.attributes.headerCover.data.attributes.formats.medium.url : pioneer.attributes.headerCover.data.attributes.url) : "..."} alt={pioneer.attributes.headerCover.data ? pioneer.attributes.headerCover.data.attributes.alternativeText : "..."} />
                                                        <img className="absolute object-contain top-0 left-0 h-full w-full z-2 rounded-2xl brightness-50 group-hover:brightness-100 duration-200" src={pioneer.attributes.profilePicture ? API_URL + (pioneer.attributes.profilePicture.data.attributes.formats.medium ? pioneer.attributes.profilePicture.data.attributes.formats.medium.url : pioneer.attributes.profilePicture.data.attributes.url) : "..."} alt={pioneer.attributes.profilePicture ? pioneer.attributes.profilePicture.data.attributes.alternativeText : "..."}/>
                                                        <div className="relative z-10 p-5 flex flex-col w-full h-full justify-end items-center">
                                                            <h6 className="text-neutral-200 font-bold">{pioneer.attributes.name} {pioneer.attributes.surname}</h6>
                                                            <span className="text-base md:text-lg text-neutral-300 font-medium">{pioneer.attributes.placeBirth} {new Date(pioneer.attributes.dateBirth).getFullYear()} - {(pioneer.attributes.dateDeath) ? new Date(pioneer.attributes.dateDeath).getFullYear() : t("Hoy")}</span>
                                                        </div>
                                                        <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black/80 to-transparent z-3 rounded-b-2xl"/>
                                                    </Link>
                                                </div>
                                            )

                                        )
                                    } else {
                                        return (
                                            <div className="w-full text-center">
                                                <p>{t("No_pioneras_busqueda")}</p>
                                            </div>
                                        )
                                    }
                                }
                            })()}
                        </div>

                        <div id="cards-alphabetic" className="flex flex-row flex-wrap hidden">
                            {(() => {
                                if (fetchPioneersAlphabetic.loading) {
                                    return (
                                        <>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-0">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-0">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                        </>
                                    )
                                } else {
                                    if (fetchPioneersAlphabetic.data && fetchPioneersAlphabetic.data.length > 0) {
                                        return (
                                            fetchPioneersAlphabetic.data.map((pioneer, index) =>
                                                <div key={index} className={`group relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square mt-4 mr-0 shadow-lg rounded-2xl group-hover:-translate-y-1 duration-200 ${(index + 1) % 2 === 0 ? "md:mr-0" : "md:mr-4"} ${(index + 1) % 3 === 0 ? "xl:mr-0" : "xl:mr-4"}`}>
                                                    <Link to={`/${i18n.language}/${t("pioneras_informaticas_url")}/${pioneer.attributes.Slug}`} className="bg-neutral-300 dark:bg-neutral-600">
                                                        <img className="absolute object-cover top-0 left-0 h-full w-full z-1 rounded-2xl" src={pioneer.attributes.headerCover ? API_URL + (pioneer.attributes.headerCover.data.attributes.formats.medium ? pioneer.attributes.headerCover.data.attributes.formats.medium.url : pioneer.attributes.headerCover.data.attributes.url) : "..."} alt={pioneer.attributes.headerCover.data ? pioneer.attributes.headerCover.data.attributes.alternativeText : "..."} />
                                                        <img className="absolute object-contain top-0 left-0 h-full w-full z-2 rounded-2xl brightness-50 group-hover:brightness-100 duration-200" src={pioneer.attributes.profilePicture !== null ? API_URL + (pioneer.attributes.profilePicture.data.attributes.formats.medium ? pioneer.attributes.profilePicture.data.attributes.formats.medium.url : pioneer.attributes.profilePicture.data.attributes.url) : "..."} alt={pioneer.attributes.profilePicture !== null ? pioneer.attributes.profilePicture.data.attributes.alternativeText : "..."}/>
                                                        <div className="relative z-10 p-5 flex flex-col w-full h-full justify-end items-center">
                                                            <h6 className="text-neutral-200 font-bold">{pioneer.attributes.name} {pioneer.attributes.surname}</h6>
                                                            <span className="text-base md:text-lg text-neutral-300 font-medium">{pioneer.attributes.placeBirth} {new Date(pioneer.attributes.dateBirth).getFullYear()} - {(pioneer.attributes.dateDeath) ? new Date(pioneer.attributes.dateDeath).getFullYear() : t("Hoy")}</span>
                                                        </div>
                                                        <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black/80 to-transparent z-3 rounded-b-2xl"/>
                                                    </Link>
                                                </div>
                                            )
                                        )
                                    } else {
                                        return (
                                            <div className="w-full text-center">
                                                <p>{t("No_pioneras_busqueda")}</p>
                                            </div>
                                        )
                                    }
                                }
                            })()}
                        </div>

                        <div id="cards-chronology" className="flex flex-row flex-wrap hidden">
                            {(() => {
                                if (fetchPioneersChronology.loading) {
                                    return (
                                        <>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-0">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-4 xl:mr-4">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                            <div className="relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square bg-neutral-300 dark:bg-neutral-600 rounded-xl flex flex-col justify-end items-center p-6 mt-4 mr-0 sm:mr-0 xl:mr-0">
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-7/12 h-5 rounded-lg mb-3" />
                                                <div className="bg-neutral-200 dark:bg-neutral-500 w-10/12 h-4 rounded-lg" />
                                            </div>
                                        </>
                                    )
                                } else {
                                    if (fetchPioneersChronology.data && fetchPioneersChronology.data.length > 0) {
                                        return (
                                            fetchPioneersChronology.data.map((pioneer, index) =>
                                                <div key={index} className={`group relative w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] xl:w-[calc(33%-1rem)] aspect-square mt-4 mr-0 shadow-lg rounded-2xl group-hover:-translate-y-1 duration-200 ${(index + 1) % 2 === 0 ? "md:mr-0" : "md:mr-4"} ${(index + 1) % 3 === 0 ? "xl:mr-0" : "xl:mr-4"}`}>
                                                    <Link to={`/${i18n.language}/${t("pioneras_informaticas_url")}/${pioneer.attributes.Slug}`} className="bg-neutral-300 dark:bg-neutral-600">
                                                        <img className="absolute object-cover top-0 left-0 h-full w-full z-1 rounded-2xl" src={pioneer.attributes.headerCover ? API_URL + (pioneer.attributes.headerCover.data.attributes.formats.medium ? pioneer.attributes.headerCover.data.attributes.formats.medium.url : pioneer.attributes.headerCover.data.attributes.url) : "..."} alt={pioneer.attributes.headerCover.data ? pioneer.attributes.headerCover.data.attributes.alternativeText : "..."} />
                                                        <img className="absolute object-contain top-0 left-0 h-full w-full z-2 rounded-2xl brightness-50 group-hover:brightness-100 duration-200" src={pioneer.attributes.profilePicture !== null ? API_URL + (pioneer.attributes.profilePicture.data.attributes.formats.medium ? pioneer.attributes.profilePicture.data.attributes.formats.medium.url : pioneer.attributes.profilePicture.data.attributes.url) : "..."} alt={pioneer.attributes.profilePicture !== null ? pioneer.attributes.profilePicture.data.attributes.alternativeText : "..."}/>
                                                        <div className="relative z-10 p-5 flex flex-col w-full h-full justify-end items-center">
                                                            <h6 className="text-neutral-200 font-bold">{pioneer.attributes.name} {pioneer.attributes.surname}</h6>
                                                            <span className="text-base md:text-lg text-neutral-300 font-medium">{pioneer.attributes.placeBirth} {new Date(pioneer.attributes.dateBirth).getFullYear()} - {(pioneer.attributes.dateDeath) ? new Date(pioneer.attributes.dateDeath).getFullYear() : t("Hoy")}</span>
                                                        </div>
                                                        <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black/80 to-transparent z-3 rounded-b-2xl"/>
                                                    </Link>
                                                </div>
                                            )
                                        )
                                    } else {
                                        return (
                                            <div className="w-full text-center">
                                                <p>{t("No_pioneras_busqueda")}</p>
                                            </div>
                                        )
                                    }
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}

