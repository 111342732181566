import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Spain, GreatBritain, France, Portugal, Italy, Germany, Catalan } from './icons/Icons'; 

const Modal = ({ show, timer }) => {
  if (!show) return null;

  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    zIndex: 1001
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000
  };

  return (
    <>
      <div style={overlayStyle}></div>
      <div style={modalStyle}>
        <p> Machine translation in progress... {timer}s</p>
      </div>
    </>
  );
};

const LanguageSelector = () => {
  const [currentLang, setCurrentLang] = useState(localStorage.getItem('i18nextLang') || 'es');
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(8);
  const [intervalId, setIntervalId] = useState(null);
  const { i18n } = useTranslation();
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const languageNames = {
    fr: 'Français',
    es: 'Español',
    en: 'English',
    pt: 'Português',
    it: 'Italiano',
    de: 'Deutsch',
    ca: 'Català' 
  };

  const languageIcons = {
    fr: France,
    es: Spain,
    en: GreatBritain,
    pt: Portugal,
    it: Italy,
    de: Germany,
    ca: Catalan
  };

  useEffect(() => {
    i18n.changeLanguage(currentLang);

    const fetchLanguages = async () => {
      try {
        setLoading(true);
        const response = await axios.get('http://127.0.0.1:5000/languages');
        let filteredLanguages = response.data.filter(lang =>
          ['fr', 'es', 'en', 'pt', 'it', 'de', 'ca'].includes(lang.code)
        );
        filteredLanguages = filteredLanguages.sort((a, b) => (a.code === 'es' ? -1 : b.code === 'es' ? 1 : 0));
        setLanguages(filteredLanguages);
      } catch (error) {
        console.error('Failed to fetch languages from LibreTranslate, using fallback list', error);
        setLanguages([
          { code: 'es', name: 'Español' },
          { code: 'ca', name: 'Català' },
          { code: 'de', name: 'Deutsch' },
          { code: 'en', name: 'English' },
          { code: 'fr', name: 'Français' },
          { code: 'it', name: 'Italiano' },
          { code: 'pt', name: 'Português' }
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchLanguages();
  }, [i18n, currentLang]);

  const handleLanguageChange = async (lang) => {
    if (loading) return;
    setLoading(true);
    setTimer(8);
    setShowModal(true);
    startTimer();
    localStorage.setItem('i18nextLang', lang);
    setCurrentLang(lang);
    i18n.changeLanguage(lang);

    if (window.location.pathname.includes('/glosario')) {
      try {
        // Pour Glosario, rechargez simplement la page
        window.location.reload();
      } catch (error) {
        console.error('Failed to reload page', error);
      } finally {
        setLoading(false);
        stopTimer();
        setShowModal(false);
      }
    } else {
      try {
        await translateAllContent(lang);
        console.log(`All server content has been requested to translate to ${lang}`);
        window.location.reload();
      } catch (error) {
        console.error('Failed to translate content', error);
      } finally {
        setLoading(false);
        stopTimer();
        setShowModal(false);
      }
    }
  };

  const translateAllContent = async (lang) => {
    const categories = [
      'about-us', 'award', 'company', 'event', 'event-type', 'field', 'nationality', 'pionera', 'press', 'tag', 'team', 'accessibility'
    ];

    for (const category of categories) {
      try {
        await axios.post(`https://pionerasinformaticas.ujaen.es/strapi/api/${category}/translate`, {
          to: lang,
          source: currentLang
        }, {
          headers: {
            Authorization: `Bearer 4684ab2ea331606cfb431969c6e8b0762518bf7280cf7e1a333764d175cce866fdd5ecd4a29c358ff863ed4bcb015c56e35fbf500e94f60d0815be849cbeb2b378a042e569f10a52d6ea6df63457e05aa1e72defc0921694f80b38a21d901c2e656a7f2c7a25c6a7170843257bbee5e49684f5adcda6df86ca54d678e46e2ac9`
          }
        });
      } catch (error) {
        console.error(`Translation error for ${category}:`, error);
      }
    }
  };

  const startTimer = () => {
    const id = setInterval(() => {
      setTimer(prev => {
        if (prev === 0) {
          clearInterval(id);
          setLoading(false);
          setShowModal(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    setIntervalId(id);
  };

  const stopTimer = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
    cursor: 'pointer'
  };

  const textStyle = {
    fontSize: '0.875rem',
    fontWeight: 'normal'
  };

  const textBoldStyle = {
    ...textStyle,
    fontWeight: 'bold'
  };

  const svgStyle = {
    width: '1rem',
    height: '1rem'
  };

  const dropdownStyle = {
    backgroundColor: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '0.375rem',
    zIndex: 1000,
    position: 'absolute',
    left: '0',
    marginTop: '0.5rem',
    width: 'max-content',
    maxHeight: '200px',
    overflowY: 'auto'
  };

  const dropdownItemStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 1rem',
    fontSize: '0.875rem',
    color: '#4A5568',
    textDecoration: 'none',
    backgroundColor: 'white',
  };

  const dropdownItemHoverStyle = {
    backgroundColor: '#EDF2F7',
  };

  return (
    <div ref={wrapperRef} className="relative inline-block text-left">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          style={buttonStyle}
        >
          {languages.map((lang) => (
            currentLang === lang.code && (
              <div key={lang.code} className="flex items-center">
                {React.createElement(languageIcons[lang.code], { className: 'h-5' })}
                <span style={textBoldStyle} className="ml-2">{languageNames[lang.code]}</span>
                <svg style={svgStyle} className="ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            )
          ))}
        </button>
      </div>

      {isOpen && (
        <div style={dropdownStyle} className="absolute right-0 mt-2 w-56 ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {languages.map(lang => (
              <button
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
                style={dropdownItemStyle}
                className="flex items-center"
                disabled={loading}
              >
                {React.createElement(languageIcons[lang.code], { className: 'h-5' })}
                <span style={lang.code === currentLang ? textBoldStyle : textStyle} className="ml-2">{languageNames[lang.code]}</span>
              </button>
            ))}
          </div>
        </div>
      )}
      
      <Modal show={showModal} timer={timer} />
    </div>
  );
};

export default LanguageSelector;


