import React, { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useFetch from "../hooks/useFetch";
import i18n from "../i18n";
import {API_URL, HEADER_HEIGHT} from "../config";
import {CloseCircle } from "../components/icons/Icons";
import Dropdown from "flowbite/src/components/dropdown";
import Seo from "../components/Seo";
import translationsES from '../locales/translationES.json'; 
import translationsEN from '../locales/translationEN.json'; 
import translationsFR from '../locales/translationFR.json'; 
import translationsIT from '../locales/translationIT.json'; 
import translationsPT from '../locales/translationPT.json';
import translationsDE from '../locales/translationDE.json'; 
import translationsCA from '../locales/translationCA.json';

export default function Eventos() {
    const location = useLocation();
    useEffect(() => {
        if(location.hash === "") {
            window.scrollTo(0, -HEADER_HEIGHT);
        }
    }, [location]);
    let typeFiltersParam = new URL(window.location).searchParams.get("types") !== null ? new URL(window.location).searchParams.get("types").split(",").map((filterName) => ([{type: "types", value:filterName }])).flat() : [];
    let filters = typeFiltersParam;

    const currentLanguage = i18n.language;
    const {t} = useTranslation();
    const qs = require('qs');
    const tab = new URL(window.location.href).searchParams.get("tab");
    const [filtersVue, setFiltersVue] = useState(typeFiltersParam);
    const [queryAll, setQueryAll] = useState(qs.stringify({
        filters: {
            event_type: {
                value: {
                    $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                }
            },
        }
    }, {
        encodeValuesOnly: true,
    }));
    const [queryAlphabetic, setQueryAlphabetic] = useState(qs.stringify({
        filters: {
            event_type: {
                value: {
                    $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                }
            },
        }
    }, {
        encodeValuesOnly: true,
    }));
    const [queryChronology, setQueryChronology] = useState(qs.stringify({
        filters: {
            event_type: {
                value: {
                    $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                }
            },
        }
    }, {
        encodeValuesOnly: true,
    }));

    // Update all queries
    const updateQueries = () => {
        setQueryAll(qs.stringify({
            filters: {
                event_type: {
                    value: {
                        $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                    }
                },
            }
        }, {
            encodeValuesOnly: true,
        }));

        setQueryAlphabetic(qs.stringify({
            filters: {
                event_type: {
                    value: {
                        $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                    }
                },
            }
        }, {
            encodeValuesOnly: true,
        }));
        setQueryChronology(qs.stringify({
            filters: {
                event_type: {
                    value: {
                        $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                    }
                },
            }
        }, {
            encodeValuesOnly: true,
        }));
    }

    // Add filter parameter in URL
    const addFilterToURLParam = (parameterType, parameter) => {
        var newurl = "";
        if(window.location.search.includes(parameterType)) {
            if(!window.location.search.includes(parameter)) {
                let currentParams = new URL(window.location.href).searchParams.get(parameterType);
                newurl = window.location.href.replace((currentParams), (`${currentParams},${parameter}`));
            }
        } else {
            if(new URL(window.location.href).search === "") {
                newurl = window.location.href + '?' + parameterType + '=' + parameter;
            } else {
                newurl = window.location.href + '&' + parameterType + '=' + parameter;
            }
        }
        window.history.pushState({ path: newurl }, '', newurl);
    }

    // Remove filter parameter from URL
    const removeFilterFromURLParam = (parameterType, parameter) => {
        if(window.location.search.includes(parameterType)) {
            if(window.location.search.includes(parameter)) {
                let newurl = window.location.href.replace(("," + parameter), "").replace((parameter) + ",", "").replace(("&" + parameterType + "=" + parameter), "").replace(("?" + parameterType + "=" + parameter), "");
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    }

    // Click on remove on filter item
    const handleRemoveFilter = (e) => {
        removeFilterFromURLParam(e.target.dataset.filterType, e.target.value);
        setFiltersVue(filtersVue.filter(filter => filter.value !== e.target.value));
        filters = filters.filter(filter => filter.value !== e.target.value);
        updateQueries();
    }

    // Function to add one parameter to the URL with the type of this parameter (ex: ?type=param)
    const addOneURLParameter = (parameterType, parameter) => {
        var newurl = "";
        if(window.location.search.includes(parameterType)) {
            let currentParams = new URL(window.location.href).searchParams.get(parameterType)
            newurl = window.location.href.replace((currentParams), (parameter));
        } else {
            if(new URL(window.location.href).search === "") {
                newurl = window.location.href + '?' + parameterType + '=' + parameter;
            } else {
                newurl = window.location.href + '&' + parameterType + '=' + parameter;
            }
        }
        window.history.pushState({ path: newurl }, '', newurl);
    }

    // Handle filters' checkbox click
    const handleCheckboxClick = (e) => {
        if (e.target.checked) { // We add a filter
            addFilterToURLParam(e.target.dataset.filterType, e.target.id);
            setFiltersVue(filtersVue.concat([{type: e.target.dataset.filterType, value: e.target.id}]));
            filters = filters.concat([{type: e.target.dataset.filterType, value: e.target.id}])
        } else { // We remove a filter
            removeFilterFromURLParam(e.target.dataset.filterType, e.target.id);
            setFiltersVue(filtersVue.filter(filter => filter.value !== e.target.id));
            filters = filters.filter(filter => filter.value !== e.target.id)
        }
        updateQueries();
    }

    useEffect(() => {
        //Initiate placement of all, alphabetic and chronology slider
        switch(tab) {
            case "az":
                handleClickButton("alphabetic");
                break;
            case "time":
                handleClickButton("chronology");
                break;
            case "all":
                handleClickButton("all");
                break;
            default:
                break;
        }

        new Dropdown(document.getElementById('dropdownTypeMenu'), document.getElementById('dropdownTypeButton'));
    })

    const handleClickButton = (buttonType) => {
        // Close all interactive tools (all categorie's section)
        document.querySelectorAll(".interactive-tool").forEach(tool => {tool.classList.add("hidden")});
        Array.from(document.getElementById("list-cards").children).forEach(cards => {cards.classList.add("hidden")})

        //Change style of buttons
        Array.from(document.getElementById("filter-buttons").children).forEach(button => {
            if(button.firstChild.id === ("button-" + buttonType)) {
                button.firstChild.classList.add("selected");
            } else {
                button.firstChild.classList.remove("selected");
            }
        })

        switch (buttonType) {
            case "all":
                addOneURLParameter("tab", "all")
                document.getElementById("cards-all").classList.remove("hidden");
                setQueryAll(qs.stringify({
                    filters: {
                        event_type: {
                            value: {
                                $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                            }
                        },
                    }
                }, {
                    encodeValuesOnly: true,
                }))
                break;
            case "alphabetic":
                addOneURLParameter("tab", "az")

                document.getElementById("filter-alphabetic").classList.remove("hidden");
                document.getElementById("cards-alphabetic").classList.remove("hidden");
                const sliderAlphabetic = document.getElementById("slider-alphabetic");
                let indexLetterURL = 0;
                let startWithLetter = "";

                if(sliderAlphabetic.children.length === 0) {
                    document.getElementById("slider-alphabetic").style.transform = `translate3d(0px, 0px, 0px)`
                } else {
                    const letterURL = (new URL(window.location.href).searchParams.get("letter") !== null) ? new URL(window.location.href).searchParams.get("letter") : sliderAlphabetic.children[0].dataset.value;
                    indexLetterURL = (Array.from(sliderAlphabetic.children).findIndex(letter => letter.dataset.value === letterURL) === -1) ? 0 : Array.from(sliderAlphabetic.children).findIndex(letter => letter.dataset.value === letterURL);
                    document.getElementById("slider-alphabetic").style.transform = `translate3d(-${indexLetterURL*100}px, 0px, 0px)`;
                    startWithLetter = sliderAlphabetic.children[indexLetterURL].dataset.value;
                }
                setQueryAlphabetic(qs.stringify({
                    filters: {
                        title: {
                            $startsWith: `${startWithLetter}`,
                        },
                        event_type: {
                            value: {
                                $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                            }
                        },
                    },
                }, {
                    encodeValuesOnly: true,
                }))

                let down_alphabetic = false;
                let scrollLeft_alphabetic;
                let x_alphabetic = 0;
                let currentScrollLeft_alphabetic = indexLetterURL*100;
                let indexCurrentLetter = indexLetterURL;

                const sliderStart_alphabetic = (e) => {
                    down_alphabetic = true;
                    scrollLeft_alphabetic = currentScrollLeft_alphabetic;
                    if(e.type === "mousedown") { x_alphabetic = e.clientX }
                    else if(e.type === "touchstart") { x_alphabetic = e.changedTouches[0].clientX; }
                    e.currentTarget.classList.remove("duration-1000");
                }
                const sliderMove_alphabetic = (e) => {
                    if (down_alphabetic) {
                        const maxSlide = ((parseInt(e.currentTarget.childElementCount,10) - 1) * e.currentTarget.dataset.space) //maxSlide = number of letter * 100 (width)
                        const clientX = (e.type === "mousemove") ? e.clientX : e.changedTouches[0].clientX;

                        if(-(scrollLeft_alphabetic + x_alphabetic - clientX) > 0) { currentScrollLeft_alphabetic = 0; }
                        else if (-(scrollLeft_alphabetic + x_alphabetic - clientX) < -maxSlide) { currentScrollLeft_alphabetic = maxSlide; }
                        else { currentScrollLeft_alphabetic = scrollLeft_alphabetic + x_alphabetic - clientX }
                        e.currentTarget.style.transform = "translate3d(" + -(currentScrollLeft_alphabetic)  + "px, 0px, 0px)"
                    }
                }
                const sliderEnd_alphabetic = (e) => {
                    down_alphabetic = false;
                    e.currentTarget.classList.add("duration-1000");
                    e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_alphabetic/e.currentTarget.dataset.space) * e.currentTarget.dataset.space)  + "px, 0px, 0px)"
                    document.documentElement.classList.remove("overflow-hidden")
                    currentScrollLeft_alphabetic = (currentScrollLeft_alphabetic % 100 < 50) ? currentScrollLeft_alphabetic - (currentScrollLeft_alphabetic % 100) : currentScrollLeft_alphabetic + (100 - currentScrollLeft_alphabetic % 100);

                    indexCurrentLetter = (Math.round(currentScrollLeft_alphabetic/e.currentTarget.dataset.space) * e.currentTarget.dataset.space) / 100;
                    addOneURLParameter("letter", sliderAlphabetic.children[indexCurrentLetter].dataset.value)
                    setTimeout(() => {
                        setQueryAlphabetic(qs.stringify({
                            filters: {
                                title: {
                                    $startsWith: sliderAlphabetic.children[indexCurrentLetter].dataset.value,
                                },
                                event_type: {
                                    value: {
                                        $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                            },
                        }, {
                            encodeValuesOnly: true,
                        }))
                    }, 1000);

                }
                const sliderLeave_alphabetic = (e) => {
                    if(down_alphabetic) {
                        sliderEnd_alphabetic(e);
                    } else {
                        down_alphabetic = false;
                        e.currentTarget.classList.add("duration-1000");
                        e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_alphabetic/e.currentTarget.dataset.space) * e.currentTarget.dataset.space) + "px, 0px, 0px)"
                        document.documentElement.classList.remove("overflow-hidden")
                    }
                }

                document.getElementById("slider-alphabetic").addEventListener('mousedown', sliderStart_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('mousemove', sliderMove_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('mouseup', sliderEnd_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('mouseleave', sliderLeave_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('touchstart', sliderStart_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('touchmove', sliderMove_alphabetic)
                document.getElementById("slider-alphabetic").addEventListener('touchend', sliderEnd_alphabetic)

                break;
            case "chronology":
                addOneURLParameter("tab", "time");

                document.getElementById("filter-chronology").classList.remove("hidden");
                document.getElementById("cards-chronology").classList.remove("hidden");

                const sliderChronology = document.getElementById("slider-chronology");
                let indexTimeURL = 0;
                let dateStartStart = "1800-01-01";
                let dateStartEnd = `${new Date().getFullYear()}-12-31`;

                if(sliderChronology.children.length === 0) {
                    document.getElementById("slider-alphabetic").style.transform = `translate3d(0px, 0px, 0px)`
                } else {
                    const timeURL = (new URL(window.location.href).searchParams.get("date") !== null) ? new URL(window.location.href).searchParams.get("date") : sliderChronology.children[0].dataset.value;
                    indexTimeURL = (Array.from(sliderChronology.children).findIndex(time => time.dataset.value === timeURL) === -1) ? 0 : Array.from(sliderChronology.children).findIndex(time => time.dataset.value === timeURL);
                    document.getElementById("slider-chronology").style.transform = `translate3d(-${indexTimeURL*130}px, 0px, 0px)`;
                    dateStartStart = `${sliderChronology.children[indexTimeURL].dataset.value}-01`;
                    dateStartEnd = `${indexTimeURL === sliderChronology.children.length-1 ? `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, "0")}-${new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate()}` : `${sliderChronology.children[indexTimeURL+1].dataset.value}-${new Date(new Date(sliderChronology.children[indexTimeURL+1].dataset.value).getFullYear(), new Date(sliderChronology.children[indexTimeURL+1].dataset.value).getMonth()+1, 0).getDate()}`}`;
                }
                setQueryChronology(qs.stringify({
                    filters: {
                        $and: [
                            {
                                dateStart: {
                                    $gte: dateStartStart,
                                },
                            },
                            {
                                dateStart: {
                                    $lt: dateStartEnd,
                                },
                            },
                        ],
                        event_type: {
                            value: {
                                $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                            }
                        },
                    },
                }, {
                    encodeValuesOnly: true,
                }))

                let down_chronology = false;
                let scrollLeft_chronology;
                let x_chronology = 0;
                let currentScrollLeft_chronology = indexTimeURL*130;
                let indexCurrentTime = indexTimeURL;

                const sliderStart_chronology = (e) => {
                    down_chronology = true;
                    scrollLeft_chronology = currentScrollLeft_chronology;
                    if(e.type === "mousedown") { x_chronology = e.clientX }
                    else if(e.type === "touchstart") { x_chronology = e.changedTouches[0].clientX; }
                    e.currentTarget.classList.remove("duration-1000");
                }
                const sliderMove_chronology = (e) => {
                    if (down_chronology) {
                        const maxSlide = ((parseInt(e.currentTarget.childElementCount,10) - 1) * e.currentTarget.dataset.space) //maxSlide = number of letter * 100 (width)
                        const clientX = (e.type === "mousemove") ? e.clientX : e.changedTouches[0].clientX;

                        if(-(scrollLeft_chronology + x_chronology - clientX) > 0) { currentScrollLeft_chronology = 0; }
                        else if (-(scrollLeft_chronology + x_chronology - clientX) < -maxSlide) { currentScrollLeft_chronology = maxSlide; }
                        else { currentScrollLeft_chronology = scrollLeft_chronology + x_chronology - clientX }
                        e.currentTarget.style.transform = "translate3d(" + -(currentScrollLeft_chronology)  + "px, 0px, 0px)"
                    }
                }
                const sliderEnd_chronology = (e) => {
                    down_chronology = false;
                    e.currentTarget.classList.add("duration-1000");
                    e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_chronology/e.currentTarget.dataset.space) * e.currentTarget.dataset.space)  + "px, 0px, 0px)"
                    document.documentElement.classList.remove("overflow-hidden")
                    currentScrollLeft_chronology = (currentScrollLeft_chronology % 130 < 50) ? currentScrollLeft_chronology - (currentScrollLeft_chronology % 130) : currentScrollLeft_chronology + (130 - currentScrollLeft_chronology % 130);

                    indexCurrentTime = (Math.round(currentScrollLeft_chronology/e.currentTarget.dataset.space) * e.currentTarget.dataset.space) / 130;
                    addOneURLParameter("date", sliderChronology.children[indexCurrentTime].dataset.value)

                    setTimeout(() => {
                        setQueryChronology(qs.stringify({
                            filters: {
                                $and: [
                                    {
                                        dateStart: {
                                            $gte: `${sliderChronology.children[indexCurrentTime].dataset.value}-01`,
                                        },
                                    },
                                    {
                                        dateStart: {
                                            $lt: `${(indexCurrentTime+1 === sliderChronology.children.length) ? `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}` : `${sliderChronology.children[indexTimeURL+1].dataset.value}-${new Date(new Date(sliderChronology.children[indexTimeURL+1].dataset.value).getFullYear(), new Date(sliderChronology.children[indexTimeURL+1].dataset.value).getMonth()+1, 0).getDate()}`}`,
                                        },
                                    },
                                ],
                                event_type: {
                                    value: {
                                        $eq: filters.filter(filter => filter.type === "types").map((filter) => ([filter.value])).flat(),
                                    }
                                },
                            },
                        }, {
                            encodeValuesOnly: true,
                        }))
                    }, 1000);
                }
                const sliderLeave_chronology = (e) => {
                    if(down_chronology) {
                        sliderEnd_chronology(e);
                    } else {
                        down_chronology = false;
                        e.currentTarget.classList.add("duration-1000");
                        e.currentTarget.style.transform = "translate3d(" + -(Math.round(currentScrollLeft_chronology/e.currentTarget.dataset.space) * e.currentTarget.dataset.space) + "px, 0px, 0px)"
                        document.documentElement.classList.remove("overflow-hidden")
                    }
                }

                document.getElementById("slider-chronology").addEventListener('mousedown', sliderStart_chronology)
                document.getElementById("slider-chronology").addEventListener('mousemove', sliderMove_chronology)
                document.getElementById("slider-chronology").addEventListener('mouseup', sliderEnd_chronology)
                document.getElementById("slider-chronology").addEventListener('mouseleave', sliderLeave_chronology)
                document.getElementById("slider-chronology").addEventListener('touchstart', sliderStart_chronology)
                document.getElementById("slider-chronology").addEventListener('touchmove', sliderMove_chronology)
                document.getElementById("slider-chronology").addEventListener('touchend', sliderEnd_chronology)

                break;
            default:
                break;
        }
    }

    const fetchEvents = useFetch(`/api/events?${queryAll}&populate=*`, 0);
    const fetchEventsAlphabetic = useFetch(`/api/events?${queryAlphabetic}&populate=*`, 0);
    const fetchEventsChronology = useFetch(`/api/events?${queryChronology}&populate=*`, 0);
    const fetchEventTypes = useFetch(`/api/event-types`, 0);
    //const fetchEventTypes = useFetch(`/api/event-types?locale=${i18n.language}`, 0);

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${t("Eventos")}`}
                metaTitle={``}
                metaDescription={`Meta descripcion de la pagina Eventos`}
                metaImage={``}
                keywords={``}
                metaRobots = {`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/${t("eventos_url")}`}
            />
            <div className="bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <div className="h-fit w-full bg-gradient-to-r from-gradient-50/50 to-gradient-900/50 mt-header-height py-4 px-4 4flex items-center justify-center">
                    <h1 className="text-center animation-shine font-bold leading-normal">{t("Eventos")}</h1>
                </div>
                <div>
                    <section className="section relative z-0 !py-4" id="requests-exhibition">
                        <div className="wrap-buttonToForm">
                            <a href="https://forms.gle/qNPLAnkW1vSrLfPz9" target="_blank" rel="noreferrer" className="buttonToForm-event">{t("Solicita_exposicion")}</a>
                        </div>
                    </section>
                    <section className="section" id="all-about-exhibition">
                        <h4 className="mb-10 font-bold">
                            {t("Todo_sobre_la_exposicion")}
                        </h4>
                        {(() => {
                            if (i18n.language === "es") {
                                return (
                                    <>
                                         <div dangerouslySetInnerHTML={{ __html: translationsES.exposicion_itinerante.replace(/\$\{i18n.language\}/g, i18n.language).replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'es' })) }} />
                                        
                                    </>
                                )
                            } else if (i18n.language === "en") {
                                return (
                                    <>
                                           <div dangerouslySetInnerHTML={{
            __html: translationsEN.exposicion_itinerante
                .replace(/\$\{i18n.language\}/g, 'en')
                .replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'en' }))
        }} />


                                   </>
                                )
                            }
                            else if (i18n.language === "fr"){
                                return (
                                    <>
                                         <div dangerouslySetInnerHTML={{
            __html: translationsFR.exposicion_itinerante
                .replace(/\$\{i18n.language\}/g, 'fr')
                .replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'fr' }))
        }} />
                                   
				   </>
                                )
                            } else if (i18n.language === "pt") {
                                return (
                                    <>
                                       <div dangerouslySetInnerHTML={{
            __html: translationsPT.exposicion_itinerante
                .replace(/\$\{i18n.language\}/g, 'pt')
                .replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'pt' }))
        }} />
            
                                    </>
                                )
                            } else if (i18n.language === "it"){
                                return (
                                    <>
                                       <div dangerouslySetInnerHTML={{
            __html: translationsIT.exposicion_itinerante
                .replace(/\$\{i18n.language\}/g, 'it')
                .replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'it' }))
        }} />
 
                                    </>
                                )
                            } else if (i18n.language === "de"){
                                return (
                                    <>
                                       <div dangerouslySetInnerHTML={{
            __html: translationsDE.exposicion_itinerante
                .replace(/\$\{i18n.language\}/g, 'de')
                .replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'de' }))
        }} />

                                    </>
                                )   
                            } else if (i18n.language === "ca") {
    				return (
        			    <>
            				 <div dangerouslySetInnerHTML={{
            __html: translationsCA.exposicion_itinerante
                .replace(/\$\{i18n.language\}/g, 'ca')
                .replace(/\$\{t\("pioneras_informaticas_url"\)\}/g, t("pioneras_informaticas_url", { lng: 'ca' }))
        }} />

        			    </>
    				)
			   }

                        })()}
                    </section>
                    <section className="section" id="all-events">
                        <h4 className="mb-4 font-bold">
                            {t("Todos_los_eventos")}
                        </h4>
                        <div className="relative pt-[80px] pb-[40px] bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-300 px-4 xs:px-10 sm:px-30 md:px-30 2xl:px-40 pt-10">
                            <div className="flex items-center justify-center">
                                <ul id="filter-buttons" className="flex flex-row flex-wrap text-neutral-200 w-fit justify-center items-center">
                                    <li className="w-fit">
                                        <button id="button-all" className={`mb-5 mx-5 w-fit px-5 py-3 bg-gradient-to-r from-gradient-50 to-gradient-200 rounded-md font-bold shadow-lg duration-200 hover:scale-105 ${(tab === "all" || tab === null) ? "selected" : ""} disabled:opacity-40`} disabled={fetchEvents.loading} onClick={() => handleClickButton("all")}>{t("Todas")}</button>
                                    </li>
                                    <li className="w-fit">
                                        <button id="button-alphabetic" className={`mb-5 mx-5 w-fit px-5 py-3 bg-gradient-to-r from-gradient-200 to-gradient-400 rounded-md font-bold shadow-lg duration-200 hover:scale-105 ${(tab === "az") ? "selected" : ""} disabled:opacity-40`} disabled={fetchEvents.loading} onClick={() => handleClickButton("alphabetic")}>A - Z</button>
                                    </li>
                                    <li className="w-fit">
                                        <button id="button-chronology" className={`mb-5 mx-5 w-fit px-5 py-3 bg-gradient-to-r from-gradient-400 to-gradient-700 rounded-md font-bold shadow-lg duration-200 hover:scale-105 ${(tab === "time") ? "selected" : ""} disabled:opacity-40`} disabled={fetchEvents.loading} onClick={() => handleClickButton("chronology")}>{t("Cronologia")}</button>
                                    </li>
                                    <li className="w-fit">
                                        <button id="dropdownTypeButton" className="font-bold text-neutral-800 dark:text-neutral-200 bg-neutral-300 dark:bg-neutral-700 hover:opacity-80 rounded-md px-5 py-3 text-center inline-flex items-center mb-5 mx-3" type="button">
                                            {t("Tipos")}
                                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        </button>
                                        <div id="dropdownTypeMenu" className="z-1000 hidden bg-neutral-200 divide-y divide-neutral-100 rounded shadow dark:bg-neutral-700 z-20 min-w-fit w-56 max-h-60 overflow-scroll">
                                            <ul className="text-neutral-700 dark:text-neutral-200 px-5 py-5 space-y-3" aria-labelledby="dropdownTypeButton">
                                                {(() => {
                                                    if(fetchEventTypes.loading) {
                                                        return (
                                                            <div className="w-full flex items-center justify-center">
                                                                <svg role="status" className="w-820h-820mr-2 tmy-4 ext-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                                </svg>
                                                            </div>
                                                        )

                                                    } else if(fetchEventTypes.data) {
                                                        return (
                                                            fetchEventTypes.data.map((type, index) =>
                                                                <li key={index}>
                                                                    <div className="flex items-center cursor-pointer group">
                                                                        <input id={type.attributes.value} data-filter-type="types" type="checkbox" value={type.attributes.name} className="w-4 h-4 text-gradient-600 bg-neutral-100 rounded border-neutral-300 dark:bg-neutral-700 dark:border-neutral-600 cursor-pointer focus:outline-gradient-500" onChange={handleCheckboxClick} checked={(new URL(window.location).searchParams.get("types") !== null) ? new URL(window.location).searchParams.get("types").split(",").indexOf(type.attributes.value) !== -1 : false} />
                                                                        <label htmlFor={type.attributes.value} className="w-full ml-2 text-neutral-900 dark:text-neutral-300 cursor-pointer group-hover:font-bold">{type.attributes.name}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                })()}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full mb-3 mt-3">
                            <span className={`interactive-tool ${(tab === "az") ? "" : "hidden"}`} id="filter-alphabetic">
                                <div className="box-content h-[60px] overflow-hidden relative select-none">
                                    <ul id="slider-alphabetic" className="inline-flex h-[60px] -left-[50px] py-0 px-[50%] relative text-center whitespace-nowrap z-2"
                                        data-space="100"
                                    >
                                        {(() => {
                                            if(fetchEvents.data !== null) {

                                                const allLetters = [...new Set(fetchEvents.data.map((event) => (event.attributes.title.slice(0,1))).sort())];
                                                return (
                                                    allLetters.map((letter, index) =>
                                                        <li key={index} className="cursor-pointer items-center justify-center inline-flex h-[60px] outline-0 overflow-hidden overflow-ellipsis w-[100px] box-border" aria-label={letter} data-value={letter}>
                                                            {letter}
                                                        </li>
                                                    )
                                                )
                                            }
                                        })()}
                                    </ul>
                                    <div className="bg-neutral-300/80 dark:bg-neutral-600/80 rounded-[50%] h-[50px] mt-[5px] -ml-[25px] w-[50px] left-[50%] absolute top-0 z-0" />
                                </div>
                            </span>
                                <span className={`interactive-tool ${(tab === "time") ? "" : "hidden"}`} id="filter-chronology">
    <div className="box-content h-[60px] overflow-hidden relative select-none">
        <ul id="slider-chronology" className="h-full -left-[58px] inline-block py-0 px-[50%] relative text-center whitespace-nowrap z-2" data-space="130">
            {(() => {
                if (fetchEvents.data !== null) {
                    const allDates = [...new Set(fetchEvents.data.map((event) => {
                        const date = new Date(event.attributes.dateStart);
                        return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}`;
                    }).sort())];

                    return allDates.map((date, index) => {
                        const dateParts = date.split('-');
                        const displayDate = new Date(dateParts[0], dateParts[1] - 1); 
                        return (
                            <li key={index}
                                className={`border-l-2 border-neutral-800 text-neutral-800 dark:border-neutral-200 dark:text-neutral-200 h-[16px] leading-[22px] overflow-visible relative w-[130px] items-center justify-center cursor-pointer inline-flex outline-0 text-ellipsis mt-[26px] ${(index === allDates.length - 1) ? "border-r-2 " : ""}`}
                                aria-label={date}
                                data-value={date}
                            >
                                <span className="text-neutral-800 dark:text-neutral-200 -left-[50%] relative inline-block h-[32px] max-w-full outline-0 overflow-hidden text-ellipsis -top-[24px] whitespace-nowrap">
                                    {displayDate.toLocaleDateString(currentLanguage, {
                                        year: 'numeric',
                                        month: 'short'
                                    })}
                                </span>
                                {(index === allDates.length - 1) ? (
                                    <span className="text-neutral-800 dark:text-neutral-200 absolute -right-[50%] w-full -top-[32px] h-[32px]">{t("Presente")}</span>
                                ) : (
                                    ""
                                )}
                            </li>
                        );
                    });
                }
            })()}
        </ul>
        <div className="border-b-8 border-b-neutral-900 dark:border-b-neutral-50 border-l-8 border-l-transparent border-r-8 border-r-transparent bottom-[8px] block h-0 -ml-[7px] top-auto w-0 z-2 relative left-[50%]" />
    </div>
</span>

                                <div className="flex flex-row items-center">
                                    <span>{t("filtros")}: </span>
                                    <ul className="flex flex-row flex-wrap">
                                        {filtersVue.map((filter, index) =>
                                            <li key={index} className="bg-gradient-500/80 dark:bg-gradient-600/40 px-4 py-2 rounded-full flex flex-row items-center mx-2 text-neutral-200 dark:text-neutral-200 font-bold my-1.5 capitalize">
                                                {t(filter.value.charAt(0).toUpperCase() + filter.value.slice(1)).split("_").join(" ")}
                                                <button className="cursor-pointer group" value={filter.value} data-filter-type={filter.type} onClick={handleRemoveFilter}>
                                                    <CloseCircle className="h-5 w-5 ml-1.5 fill-neutral-200 dark:fill-gradient-600 group-hover:rotate-90 transition-transform duration-700 pointer-events-none" />
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div id="list-cards" className="w-full background-fancy-border">

                                <div id="cards-all">
                                    {(() => {
                                        if(fetchEvents.loading) {
                                            return (
                                                <div className="w-full flex items-center justify-center">
                                                    <svg role="status" className="w-20 h-20 mr-2 my-4 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            )
                                        } else {
                                            if (fetchEvents.data && fetchEvents.data.length > 0) {
                                                return (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                                                        {fetchEvents.data.slice(0).reverse().map((event, index) =>
                                                            <Link key={index} to={`/${i18n.language}/${t("eventos_url")}/${event.attributes.Slug}`} className={`${(new Date() <= new Date(event.attributes.dateEnd)) ? "md:col-span-2 md:row-span-2" : ""} relative aspect-square rounded-xl flex flex-col justify-end items-center p-6`}>
                                                                <img className="absolute top-0 left-0 w-full h-full z-0 rounded-xl object-cover" src={event.attributes.coverImage.data ? API_URL + (event.attributes.coverImage.data.attributes.formats.medium ? event.attributes.coverImage.data.attributes.formats.medium.url : event.attributes.coverImage.data.attributes.url) : "..."} alt={event.attributes.coverImage.data ? event.attributes.coverImage.data.attributes.alternativeText : "..."}/>
                                                                <h5 className="z-5 text-neutral-200">
                                                                    {event.attributes.title}
                                                                </h5>
                                                                <div className="z-5 text-neutral-200">
                                                                    {event.attributes.institution}{event.attributes.event_type.data ? ` - ${event.attributes.event_type.data.attributes.name}` : ""} 
                                                                </div>
                                                                <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black/80 to-transparent z-3 rounded-b-2xl"/>
                                                            </Link>
                                                        )}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="w-full text-center">
                                                        <p>{t("No_eventos_busqueda")}</p>
                                                    </div>
                                                )
                                            }
                                        }
                                    })()}
                                </div>

                                <div id="cards-alphabetic" className="hidden">
                                    {(() => {
                                        if(fetchEventsAlphabetic.loading) {
                                            return (
                                                <div className="w-full flex items-center justify-center">
                                                    <svg role="status" className="w-20 h-20 mr-2 my-4 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            )
                                        } else {
                                            if (fetchEventsAlphabetic.data && fetchEventsAlphabetic.data.length > 0) {
                                                return (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                                                        {fetchEventsAlphabetic.data.map((event, index) =>
                                                            <Link key={index} to={`/${i18n.language}/${t("eventos_url")}/${event.attributes.Slug}`} className={`${(new Date() <= new Date(event.attributes.dateEnd)) ? "md:col-span-2 md:row-span-2" : ""} relative aspect-square rounded-xl flex flex-col justify-end items-center p-6`}>
                                                                <img className="absolute top-0 left-0 w-full h-full z-0 rounded-xl object-cover" src={event.attributes.coverImage.data ? API_URL + (event.attributes.coverImage.data.attributes.formats.medium ? event.attributes.coverImage.data.attributes.formats.medium.url : event.attributes.coverImage.data.attributes.url) : "..."} alt={event.attributes.coverImage.data ? event.attributes.coverImage.data.attributes.alternativeText : "..."} />
                                                                <h5 className="z-5 text-neutral-200">
                                                                    {event.attributes.title}
                                                                </h5>
                                                                <div className="z-5 text-neutral-200">
                                                                    {event.attributes.institution}{event.attributes.event_type.data ? ` - ${event.attributes.event_type.data.attributes.name}` : ""}
                                                                </div>
                                                                <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black/80 to-transparent z-3 rounded-b-2xl"/>
                                                            </Link>
                                                        )}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="w-full text-center">
                                                        <p>{t("No_eventos_busqueda")}</p>
                                                    </div>
                                                )
                                            }
                                        }
                                    })()}
                                </div>

                                <div id="cards-chronology" className="hidden">
                                    {(() => {
                                        if(fetchEventsChronology.loading) {
                                            return (
                                                <div className="w-full flex items-center justify-center">
                                                    <svg role="status" className="w-20 h-20 mr-2 my-4 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                                    </svg>
                                                </div>
                                            )
                                        } else {
                                            if (fetchEventsChronology.data && fetchEventsChronology.data.length > 0) {
                                                return (
                                                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                                                        {fetchEventsChronology.data.map((event, index) =>
                                                            <Link key={index} to={`/${i18n.language}/${t("eventos_url")}/${event.attributes.Slug}`} className={`${(new Date() <= new Date(event.attributes.dateEnd)) ? "md:col-span-2 md:row-span-2" : ""} relative aspect-square rounded-xl flex flex-col justify-end items-center p-6`}>
                                                                <img className="absolute top-0 left-0 w-full h-full z-0 rounded-xl object-cover" src={event.attributes.coverImage.data ? API_URL + (event.attributes.coverImage.data.attributes.formats.medium ? event.attributes.coverImage.data.attributes.formats.medium.url : event.attributes.coverImage.data.attributes.url) : "..."} alt={event.attributes.coverImage.data ? event.attributes.coverImage.data.attributes.alternativeText : "..."}/>
                                                                <h5 className="z-5 text-neutral-200">
                                                                    {event.attributes.title}
                                                                </h5>
                                                                <div className="z-5 text-neutral-200">
                                                                    {event.attributes.institution}{event.attributes.event_type.data ? ` - ${event.attributes.event_type.data.attributes.name}` : ""} 
                                                                </div>
                                                                <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black/80 to-transparent z-3 rounded-b-2xl"/>
                                                            </Link>
                                                        )}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className="w-full text-center">
                                                        <p>{t("No_eventos_busqueda")}</p>
                                                    </div>
                                                )
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </>

    );
}
