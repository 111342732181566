import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Pages & layout imports
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/Homepage';
import PionerasInformaticas from './pages/PionerasInformaticas';
import Eventos from './pages/Eventos';
import Evento from './pages/Evento';
import Glosario from './pages/Glosario';
import Libro from './pages/Libro';
import Pionera from "./pages/Pionera";
import Category from "./pages/Category";
import AboutUs from "./pages/AboutUs";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Accesibilidad from "./pages/Accesibilidad"

import { useTranslation } from "react-i18next";
import Error404 from "./pages/Error404";
import Press from "./pages/Press";
import i18n from "./i18n";




function App() {
    const { i18n, t } = useTranslation();
    const [theme, setTheme] = useState("light")
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      document.documentElement.lang = lng;
  }
  const handleChangeTheme = (newTheme) => {
     setTheme(newTheme);
  }

  return (
      <>

          {/*<div id="readspeaker_button1" className="rs_skip rsbtn rs_preserve !z-1000 !w-fit !top-[5px] !right-[10px] !fixed !m-0" >
            <a rel="nofollow" className="rsbtn_play" accessKey="L"
               title="Escuchaesta p&aacute;gina utilizando ReadSpeaker webReader"
               href={`https://app-eu.readspeaker.com/cgi-bin/rsent?customerid=11603&lang=${i18n.language}&voice=Lola&url=http://keops.ujaen.es:6927/${i18n.language}/`}>
          <span className="rsbtn_left rsimg rspart">
            <span className="rsbtn_text">
              <span>Escuchar</span>
            </span>
          </span>
                <span className="rsbtn_right rsimg rsplay rspart"></span>
            </a>
        </div>*/}
        <div className="App">
          <Router>
            <Header changeLanguage={changeLanguage} theme={theme} setTheme={handleChangeTheme}/>
            <Routes>
                <Route path="/" element={<Navigate replace to="/es/" />} />
                <Route path="/es/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'es'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'es'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'es'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'es'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'es'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'es'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'es'})} element={<Navigate replace to={"/es"} />} />
                    <Route path={`${t("categorias_url", {lng: 'es'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'es'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'es'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'es'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'es'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'es'})} element={<Accesibilidad />} />
                </Route>
    
                <Route path="/en/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'en'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'en'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'en'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'en'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'en'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'en'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'en'})} element={<Navigate replace to={"/en"} />} />
                    <Route exact path={`${t("categorias_url", {lng: 'en'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'en'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'en'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'en'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'en'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'en'})} element={<Accesibilidad />} />
                </Route>
                <Route path="/fr/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'fr'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'fr'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'fr'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'fr'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'fr'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'fr'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'fr'})} element={<Navigate replace to={"/fr"} />} />
                    <Route exact path={`${t("categorias_url", {lng: 'fr'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'fr'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'fr'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'fr'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'fr'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'fr'})} element={<Accesibilidad />} />
                </Route>
                <Route path="/pt/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'pt'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'pt'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'pt'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'pt'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'pt'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'pt'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'pt'})} element={<Navigate replace to={"/pt"} />} />
                    <Route exact path={`${t("categorias_url", {lng: 'pt'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'pt'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'pt'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'pt'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'pt'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'pt'})} element={<Accesibilidad />} />
                </Route>
                <Route path="/it/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'it'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'it'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'it'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'it'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'it'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'it'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'it'})} element={<Navigate replace to={"/it"} />} />
                    <Route exact path={`${t("categorias_url", {lng: 'it'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'it'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'it'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'it'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'it'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'it'})} element={<Accesibilidad />} />
                </Route>
                <Route path="/de/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'de'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'de'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'de'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'de'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'de'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'de'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'de'})} element={<Navigate replace to={"/de"} />} />
                    <Route exact path={`${t("categorias_url", {lng: 'de'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'de'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'de'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'de'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'de'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'de'})} element={<Accesibilidad />} />
                </Route>
                <Route path="/ca/">
                    <Route exact path="" element={<HomePage />} />
                    <Route exact path={t("pioneras_informaticas_url", {lng: 'ca'})} element={<PionerasInformaticas />} />
                    <Route exact path={`${t("pioneras_informaticas_url", {lng: 'ca'})}/:slug`} element={<Pionera />} />
                    <Route exact path={t("eventos_url", {lng: 'ca'})} element={<Eventos />} />
                    <Route exact path={`${t("eventos_url", {lng: 'ca'})}/:slug`} element={<Evento />} />
                    <Route exact path={t("glosario_url", {lng: 'ca'})} element={<Glosario />} />
                    <Route exact path={t("libro_url", {lng: 'ca'})} element={<Libro />} />
                    <Route exact path={t("categorias_url", {lng: 'ca'})} element={<Navigate replace to={"/ca"} />} />
                    <Route exact path={`${t("categorias_url", {lng: 'ca'})}/:category`} element={<Category />} />
                    <Route exact path={t("acerca_de_url", {lng: 'ca'})} element={<AboutUs />} />
                    <Route exact path={t("equipo_patrocinadores_url", {lng: 'ca'})} element={<Team />} />
                    <Route exact path={t("medios_comunicacion_url", {lng: 'ca'})} element={<Press />} />
                    <Route exact path={t("contact", {lng: 'ca'})} element={<Contact />} />
                    <Route exact path={t("accesibilidad", {lng: 'ca'})} element={<Accesibilidad />} />
                </Route>

                <Route path="*" element={<Error404 />} />
            </Routes>
            <Footer theme={theme} />
          </Router>
        </div>
    </>
  );
}

export default App;