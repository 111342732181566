import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import i18n from "../i18n";
import Seo from "../components/Seo";
import {useLocation} from "react-router-dom";
import {HEADER_HEIGHT} from "../config";

export default function Libro() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, -HEADER_HEIGHT);
    }, [location]);
    const {t} = useTranslation();

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${t("Libro")}`}
                metaTitle={``}
                metaDescription={`Meta descripcion de la pagina Libro`}
                metaImage={``}
                keywords={``}
                metaRobots = {`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/${t("libro_url")}`}
            />
            <div className="h-fit w-full bg-gradient-to-r from-gradient-50/50 to-gradient-900/50 mt-header-height py-4 px-4 4flex items-center justify-center">
                <h1 className="text-center animation-shine font-bold leading-normal">{t("Libro")}</h1>
            </div>
            <div className="bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <section className="section" id="all-about-exhibition">
                    <h4 className="mb-6 font-bold">
                        {t("Proxima_publicacion_Libro")}
                    </h4>
                    {(() => {
    			return (
        		   <>
            			<p>{t("catalogo_libro_descripcion")}</p>
        	           </>
    			)
	            })()}

                </section>
            </div>
        </>
    );
}
