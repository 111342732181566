import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import useFetch from '../hooks/useFetch';
import { useLocation, useParams } from "react-router-dom";
import {
    ChevronDown,
    ChevronLeft,
    ChevronRight, FingerPointer,
    Handshake,
    HeartPulse,
    OpenHand,
    Quotes
} from "../components/icons/Icons";
import Carousel from "../components/Carousel";
import '../index.css';
import { API_URL, HEADER_HEIGHT } from "../config";
import i18n from '../i18n';
import { useTranslation } from "react-i18next";
import Seo from "../components/Seo";
import qs from "qs";

const manualTranslations = {
    'arquitectura-organizacion-computadoras': {
        'es': 'Arquitectura y organización de computadoras',
        'fr': 'Architecture et organisation des ordinateurs',
        'ca': 'Arquitectura i organització de computadores',
        'it': 'Architettura e organizzazione dei computer',
        'de': 'Computerarchitektur und -organisation',
        'en': 'Computer architecture and organization',
        'pt': 'Arquitetura e organização de computadores'
    },
    'graficos-computadora-visualizacion': {
        'es': 'Gráficos de computadora y visualización',
        'fr': 'Graphiques informatiques et visualisation',
        'ca': 'Gràfics per ordinador i visualització',
        'it': 'Grafica e visualizzazione del computer',
        'de': 'Computergrafik und -visualisierung',
        'en': 'Computer graphics and visualization',
        'pt': 'Gráficos e visualização de computador'
    },
    'redes-computadores': {
        'es': 'Redes de computadores',
        'fr': 'Réseaux informatiques',
        'ca': 'Xarxes de computadors',
        'it': 'Reti di computer',
        'de': 'Computernetzwerke',
        'en': 'Computer networks',
        'pt': 'Redes de computadores'
    },
    'seguridad-informatica-criptografia': {
        'es': 'Seguridad informática y criptografía',
        'fr': 'Sécurité informatique et cryptographie',
        'ca': 'Seguretat informàtica i criptografia',
        'it': 'Sicurezza informatica e crittografia',
        'de': 'IT-Sicherheit und Kryptographie',
        'en': 'Computer security and cryptography',
        'pt': 'Segurança informática e criptografia'
    },
    'teoria-computacion': {
        'es': 'Teoría de la computación',
        'fr': 'Théorie de la computation',
        'ca': 'Teoria de la computació',
        'it': 'Teoria della computazione',
        'de': 'Berechenbarkeitstheorie',
        'en': 'Theory of computation',
        'pt': 'Teoria da computação'
    },
    'ciencia-datos': {
        'es': 'Ciencia de datos',
        'fr': 'Science des données',
        'ca': 'Ciència de dades',
        'it': 'Scienza dei dati',
        'de': 'Datenwissenschaft',
        'en': 'Data science',
        'pt': 'Ciência de dados'
    },
    'inteligencia-artificial': {
        'es': 'Inteligencia Artificial',
        'fr': 'Intelligence Artificielle',
        'ca': 'Intel·ligència Artificial',
        'it': 'Intelligenza Artificiale',
        'de': 'Künstliche Intelligenz',
        'en': 'Artificial Intelligence',
        'pt': 'Inteligência Artificial'
    },
    'matematicas': {
        'es': 'Matemáticas',
        'fr': 'Mathématiques',
        'ca': 'Matemàtiques',
        'it': 'Matematica',
        'de': 'Mathematik',
        'en': 'Mathematics',
        'pt': 'Matemática'
    },
    'interfaz-computadora-humano': {
        'es': 'Interfaz computadora-humano',
        'fr': 'Interface homme-machine',
        'ca': 'Interfície home-màquina',
        'it': 'Interfaccia uomo-macchina',
        'de': 'Mensch-Maschine-Schnittstelle',
        'en': 'Human-computer interface',
        'pt': 'Interface homem-computador'
    },
    'lenguajes-programacion': {
        'es': 'Lenguajes de programación',
        'fr': 'Langages de programmation',
        'ca': 'Llenguatges de programació',
        'it': 'Linguaggi di programmazione',
        'de': 'Programmiersprachen',
        'en': 'Programming languages',
        'pt': 'Linguagens de programação'
    }
};

const translateField = (key) => {
    const lang = i18n.language;
    return manualTranslations[key] ? manualTranslations[key][lang] : key;
};

export default function Pionera() {
    const location = useLocation();
    const { t } = useTranslation();
    const { slug } = useParams();
    const [progressTimelineStep, setProgressTimelineStep] = useState("life");

    const cardRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, -HEADER_HEIGHT);
    }, [location]);

    const youtubeLinkToEmbedded = (link) => {
        const url = new URL(link);
        if (url.pathname.split("/")[1] === "embed") {
            return link;
        } else if (url.href.indexOf("?v=") !== -1) {
            const urlParams = new URLSearchParams(url.search);
            return "https://www.youtube.com/embed/" + urlParams.get('v');
        } else {
            return "https://www.youtube.com/embed" + url.pathname;
        }
    };

    const queryPioneer = qs.stringify({
        filters: {
            slug: {
                $eq: slug,
            },
        },
        populate: {
            education: {
                populate: '*'
            },
            career: {
                populate: '*'
            },
            employer: {
                populate: '*'
            },
            twoSentences: {
                populate: '*'
            },
            profilePicture: {
                populate: '*'
            },
            headerCover: {
                populate: '*'
            },
            links: {
                populate: '*'
            },
            comics: {
                populate: '*'
            },
            nationality: {
                populate: '*'
            },
            awards: {
                populate: '*'
            },
            fields: {
                populate: '*'
            },
            seo: {
                populate: '*'
            },
            localizations: {
                populate: '*'
            },
        }
    }, {
        encodeValuesOnly: true,
    });

    const fetchPioneer = useFetch(`/api/pioneras?${queryPioneer}`, 0);

    useEffect(() => {
        if (fetchPioneer.data) {
            const identityCard = cardRef.current;
            if (identityCard) {
                const handleCardClick = () => {
                    identityCard.classList.toggle('is-flipped');
                };
                identityCard.addEventListener('click', handleCardClick);
                return () => {
                    identityCard.removeEventListener('click', handleCardClick);
                };
            }
        }
    }, [fetchPioneer.data]);

    const nextStep = () => {
        const currentStep = document.getElementById(progressTimelineStep);
        const currentStepContent = document.getElementById(progressTimelineStep + "-content");
        const nextStep = currentStep.nextElementSibling;
        const nextStepContent = currentStepContent.nextElementSibling;
        const sliderContent = document.getElementById("slider-content-step-progress");
        const indexStepContent = Array.prototype.indexOf.call(sliderContent.children, currentStepContent);

        if (nextStep !== null) {
            Array.from(sliderContent.childNodes).forEach((stepContent, index) => {
                nextStepContent.classList.remove("absolute");
                currentStepContent.classList.add("absolute");
                stepContent.classList.replace("translate-x-" + ((index * 100) - ((indexStepContent) * 100)) + "p", "translate-x-" + ((index * 100) - ((indexStepContent + 1) * 100)) + "p");
            });

            nextStep.classList.replace("step-progress-item-uncompleted", "step-progress-item-completed");
            setProgressTimelineStep(nextStep.id);
        }
    };

    const previousStep = () => {
        const currentStep = document.getElementById(progressTimelineStep);
        const currentStepContent = document.getElementById(progressTimelineStep + "-content");
        const previousStep = currentStep.previousElementSibling;
        const previousStepContent = currentStepContent.previousElementSibling;
        const sliderContent = document.getElementById("slider-content-step-progress");
        const indexStepContent = Array.prototype.indexOf.call(sliderContent.children, currentStepContent);

        if (previousStep !== null) {
            Array.from(sliderContent.childNodes).forEach((stepContent, index) => {
                previousStepContent.classList.remove("absolute");
                currentStepContent.classList.add("absolute");
                stepContent.classList.replace("translate-x-" + ((index * 100) - ((indexStepContent) * 100)) + "p", "translate-x-" + ((index * 100) - ((indexStepContent - 1) * 100)) + "p");
            });

            currentStep.classList.replace("step-progress-item-completed", "step-progress-item-uncompleted");
            setProgressTimelineStep(previousStep.id);
        }
    };

    const clickStep = (e) => {
        const sliderContent = document.getElementById("slider-content-step-progress");
        const slider = document.getElementById("slider-step-progress");
        const targetStep = e.target.parentNode;
        const targetStepContent = document.getElementById(targetStep.id + "-content");
        const indexStepContent = Array.prototype.indexOf.call(sliderContent.children, targetStepContent);

        Array.from(sliderContent.childNodes).forEach((stepContent, index) => {
            const lastTranslate = Array.from(stepContent.classList).find(className => className.match(/^translate-x/g));
            stepContent.classList.replace(lastTranslate, "translate-x-" + ((index * 100) - ((indexStepContent) * 100)) + "p");

            if (index === indexStepContent) {
                stepContent.classList.remove("absolute");
            } else {
                stepContent.classList.add("absolute");
            }
        });
        Array.from(slider.childNodes).forEach((step, index) => {
            if (index <= indexStepContent) {
                setTimeout(function () {
                    step.classList.replace("step-progress-item-uncompleted", "step-progress-item-completed");
                }, (index * 300));
            } else {
                setTimeout(function () {
                    step.classList.replace("step-progress-item-completed", "step-progress-item-uncompleted");
                }, (slider.childNodes.length - 1 - index) * 300);
            }
        });
        setProgressTimelineStep(targetStep.id);
    };

    const name_surname = slug.split("-")[0].charAt(0).toUpperCase() + slug.split("-")[0].slice(1) + " " + slug.split("-")[slug.split("-").length - 2].charAt(0).toUpperCase() + slug.split("-")[slug.split("-").length - 2].slice(1);

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${fetchPioneer.data && fetchPioneer.data[0].attributes.seo ? fetchPioneer.data[0].attributes.name + " " + fetchPioneer.data[0].attributes.surname : name_surname}`}
                metaTitle={fetchPioneer.data && fetchPioneer.data[0].attributes.seo ? fetchPioneer.data[0].attributes.seo.metaTitle : name_surname}
                metaDescription={fetchPioneer.data && fetchPioneer.data[0].attributes.seo ? fetchPioneer.data[0].attributes.seo.metaDescription : `Description of the ${name_surname}`}
                metaImage={fetchPioneer.data && fetchPioneer.data[0].attributes.seo ? (fetchPioneer.data[0].attributes.seo.metaImage.data.attributes.formats.small ? fetchPioneer.data[0].attributes.seo.metaImage.data.attributes.formats.small.url : fetchPioneer.data[0].attributes.seo.metaImage.data.attributes.url) : ``}
                keywords={fetchPioneer.data && fetchPioneer.data[0].attributes.seo ? fetchPioneer.data[0].attributes.seo.keywords : `${name_surname}`}
                metaRobots={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/${t("pioneras_informaticas_url")}/${slug}`}
            />
            <div className="relative h-[500px] w-full flex justify-center">
                {(() => {
                    if (fetchPioneer.loading) {
                        return (
                            <div className="bg-neutral-500 dark:bg-neutral-700 h-full w-full" />
                        )
                    } else {
                        return (
                            <>
                                <img src={fetchPioneer.data[0].attributes.headerCover ? API_URL + (fetchPioneer.data[0].attributes.headerCover.data.attributes.formats.medium ? fetchPioneer.data[0].attributes.headerCover.data.attributes.formats.medium.url : fetchPioneer.data[0].attributes.headerCover.data.attributes.url) : "..."} alt={fetchPioneer.data[0].attributes.headerCover.data ? fetchPioneer.data[0].attributes.headerCover.data.attributes.alternativeText : "..."} className="object-cover h-full w-full" />
                                <div className="absolute w-full h-full bg-neutral-900/50" />
                            </>
                        )
                    }
                })()}
                <div className="absolute w-full h-[calc(100%-160px)] top-40 sm:bottom-24 md:bottom-28 pb-12 flex flex-col items-center justify-center text-neutral-200 px-8 sm:px-14 md:px-28 lg:px-36 xl-64">
                    {(() => {
                        if (fetchPioneer.loading) {
                            return (
                                <div className="w-full flex flex-col items-center justify-center">
                                    <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-6/12 rounded-full mb-5 h-16" />
                                    <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-3/12 rounded-full mb-8 h-8" />
                                    <div className="w-7/12 flex flex-col space-y-3">
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-8" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-10/12 rounded-full h-8" />
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <>
                                    <h1 className="mb-5">{fetchPioneer.data[0].attributes.name} {fetchPioneer.data[0].attributes.surname}</h1>
                                    <span className="text-xl mb-8">
                                        {fetchPioneer.data[0].attributes.placeBirth !== null ? fetchPioneer.data[0].attributes.placeBirth : ""} {fetchPioneer.data[0].attributes.dateBirth !== null ? fetchPioneer.data[0].attributes.dateBirth.split('-')[0] : ""} - {(() => {
                                            if (fetchPioneer.data[0].attributes.dateDeath) {
                                                return (fetchPioneer.data[0].attributes.dateDeath.split('-')[0])
                                            } else {
                                                return (t("Hoy"))
                                            }
                                        })()}
                                    </span>

                                    <ul>
                                        <li className="max-w-[1000px] text-justify">
                                            <ReactMarkdown className="markdown" linkTarget="_top">
                                                {fetchPioneer.data[0].attributes.twoSentences.sentence1 + " " + fetchPioneer.data[0].attributes.twoSentences.sentence2}
                                            </ReactMarkdown>
                                        </li>
                                    </ul>
                                </>
                            )
                        }
                    })()}
                </div>
                <a href="#identity" className="absolute bottom-2 hover:bottom-1 transition-all duration-200 flex items-center justify-center">
                    <ChevronDown className="h-10 w-10 " color="white" strokeWidth={2} />
                </a>
            </div>
            <div className="bg-neutral-100 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-300 px-6 md:px-16 xl:px-10 pt-20 pb-20 h-full">{/*px-4 sm:px-16 md:px-25 lg:px-32 xl:px-56*/}
                <div className="flex flex-col xl:flex-row space-x-0 space-y-20 xl:space-x-5 xl:space-y-0 mb-20 ">
                    <section id="identity" className="w-full xl:w-1/2 xl:min-w-[48rem]">
                        <div className="w-full max-w-lg md:max-w-3xl h-164 xs:h-108 mx-auto identity-card-scene">
                            <div className="card h-full w-full transition-transform duration-1000 cursor-pointer relative"
                                id="identity-card" ref={cardRef}>

                                {(() => {
                                    if (fetchPioneer.loading) {
                                        return (
                                            <>
                                                <div className="xs:grid bg-neutral-500 dark:bg-neutral-700 h-full w-full rounded-2xl p-8">
                                                    <div className="mb-3 md:mb-5 lg:mb-0 flex flex-col xs:flex-row lg:flex-col m-0 items-center justify-center col-start-1 col-end-1 row-start-1 row-end-2 md:row-end-3">
                                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 h-48 w-36 sm:h-52 sm:w-40 md:h-60 md:w-48 lg:h-64 lg:w-52 transition-all duration-200 border-2 border-neutral-900 rounded-lg" />
                                                    </div>
                                                    <div className="flex flex-col ml-0 md:ml-10 items-center justify-center md:justify-end mb-3 md:mb-5 col-start-2 col-end-3 row-start-1 row-end-1">
                                                        <ul className="w-full flex flex-col md:flex-row space-y-3 sm:space-y-5 md:space-y-0 mb-3 sm:mb-5">
                                                            <li className="flex flex-col items-center xs:items-start w-full md:w-1/2">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Nombre")}
                                                                </span>
                                                                <span className="animate-pulse bg-neutral-400 dark:bg-neutral-500 mt-2 h-5 w-6/12 rounded-full flex-col justify-center w-full text-center xs:text-left" />
                                                            </li>
                                                            <li className="flex flex-col items-center xs:items-start w-full md:w-1/2">
                                                                <span className="text-xl sm:text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Apellido")}
                                                                </span>
                                                                <span className="animate-pulse bg-neutral-400 dark:bg-neutral-500 mt-2 h-5 w-8/12 rounded-full flex-col justify-center w-full text-center xs:text-left" />
                                                            </li>
                                                        </ul>
                                                        <ul className="w-full">
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Nacionalidad")}
                                                                </span>
                                                                <span className="animate-pulse bg-neutral-400 dark:bg-neutral-500 mt-2 h-5 w-5/12 rounded-full flex-col justify-center w-full text-center xs:text-left" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        className="flex flex-col ml-0 md:ml-10 items-center justify-start col-start-1 md:col-start-2 col-end-3 row-start-2 row-end-3">
                                                        <ul className="space-y-3 sm:space-y-5 w-full">
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Nacimiento")}
                                                                </span>
                                                                <span className="animate-pulse bg-neutral-400 dark:bg-neutral-500 mt-2 h-5 w-10/12 rounded-full flex-col justify-center w-full text-center xs:text-left" />
                                                            </li>
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Fallecimiento")}
                                                                </span>
                                                                <span className="animate-pulse bg-neutral-400 dark:bg-neutral-500 mt-2 h-5 w-80 rounded-full flex-col justify-center w-full text-center xs:text-left" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <div
                                                    className="absolute w-full h-full text-neutral-200 text-center font-bold text-5xl xs:grid bg-gradient-to-r from-gradient-50 to-gradient-900 rounded-2xl p-8"
                                                    id="identity-card-front">
                                                    <div className="mb-3 md:mb-5 lg:mb-0 flex flex-col xs:flex-row lg:flex-col m-0 items-center justify-center col-start-1 col-end-1 row-start-1 row-end-2 md:row-end-3">
                                                        <div
                                                            className="h-48 w-36 sm:h-52 sm:w-40 md:h-60 md:w-48 lg:h-64 lg:w-52 transition-all duration-200 border-2 border-neutral-900 rounded-lg shadow-lg">
                                                            <img className="object-cover h-full w-full rounded-lg" src={fetchPioneer.data[0].attributes.profilePicture !== null ? API_URL + (fetchPioneer.data[0].attributes.profilePicture.data.attributes.formats.medium ? fetchPioneer.data[0].attributes.profilePicture.data.attributes.formats.medium.url : fetchPioneer.data[0].attributes.profilePicture.data.attributes.url) : "..."} alt={fetchPioneer.data[0].attributes.profilePicture !== null ? fetchPioneer.data[0].attributes.profilePicture.data.attributes.alternativeText : "..."} />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col ml-0 md:ml-10 items-center justify-center md:justify-end mb-3 md:mb-5 col-start-2 col-end-3 row-start-1 row-end-1">
                                                        <ul className="w-full flex flex-col md:flex-row space-y-3 sm:space-y-5 md:space-y-0 mb-3 sm:mb-5">
                                                            <li className="flex flex-col items-center xs:items-start w-full md:w-1/2">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Nombre")}
                                                                </span>
                                                                <span className="text-base md:text-lg font-normal flex flex-col justify-center w-full text-center xs:text-left">
                                                                    {fetchPioneer.data[0].attributes.name}
                                                                </span>
                                                            </li>
                                                            <li className="flex flex-col items-center xs:items-start w-full md:w-1/2">
                                                                <span className="text-xl md:text-2xl font font-bold w-full text-center xs:text-left">
                                                                    {t("Apellido")}
                                                                </span>
                                                                <span className="text-base md:text-lg font-normal flex flex-col justify-center w-full text-center xs:text-left">
                                                                    {fetchPioneer.data[0].attributes.surname}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <ul className="w-full">
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Nacionalidad")}
                                                                </span>
                                                                <span className="text-base md:text-lg font-normal flex flex-col justify-center w-full text-center xs:text-left">
                                                                    {(() => {
                                                                        if (fetchPioneer.data[0].attributes.nationality.data !== null) {
                                                                            return (
                                                                                fetchPioneer.data[0].attributes.nationality.data.attributes.name
                                                                            )
                                                                        } else {
                                                                            return ("/")
                                                                        }
                                                                    })()}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        className="flex flex-col ml-0 md:ml-10 items-center justify-start col-start-1 md:col-start-2 col-end-3 row-start-2 row-end-3">
                                                        <ul className="space-y-3 sm:space-y-5 w-full">
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Nacimiento")}
                                                                </span>
                                                                <span className="text-base md:text-lg font-normal flex flex-col justify-center w-full text-center xs:text-left">
                                                                    {(() => {
                                                                        return (new Date(fetchPioneer.data[0].attributes.dateBirth).toLocaleDateString(i18n.language, {
                                                                            year: 'numeric',
                                                                            month: 'long',
                                                                            day: 'numeric'
                                                                        }) + " - " + fetchPioneer.data[0].attributes.placeBirth)
                                                                    })()}
                                                                </span>
                                                            </li>
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <span className="text-xl md:text-2xl font-bold w-full text-center xs:text-left">
                                                                    {t("Fallecimiento")}
                                                                </span>
                                                                <span className="text-base md:text-lg font-normal flex flex-col justify-center w-full text-center xs:text-left">
                                                                    {(() => {
                                                                        if (fetchPioneer.data[0].attributes.dateDeath && fetchPioneer.data[0].attributes.placeDeath) {
                                                                            if (i18n.language === "es") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('es-ES', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " anos) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }
                                                                            if (i18n.language === "en") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('en-GB', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " years old) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }
                                                                            if (i18n.language === "fr") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('fr-FR', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " ans) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }
                                                                            if (i18n.language === "pt") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('pt-PT', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " anos) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }
                                                                            if (i18n.language === "it") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('it-IT', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " anni) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }
                                                                            if (i18n.language === "de") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('de-DE', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " jahre) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }
                                                                            if (i18n.language === "ca") {
                                                                                return (new Date(fetchPioneer.data[0].attributes.dateDeath).toLocaleDateString('ca-CA', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric'
                                                                                }) + " (" + (new Date(fetchPioneer.data[0].attributes.dateDeath).getFullYear() - new Date(fetchPioneer.data[0].attributes.dateBirth).getFullYear()) + " years old) - " + fetchPioneer.data[0].attributes.placeDeath)
                                                                            }

                                                                        } else {
                                                                            return ("/")
                                                                        }
                                                                    })()}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <span
                                                        className="absolute bottom-0 right-0 text-sm sm:text-base font-normal p-2 flex flex-row items-center text-neutral-300">{t("Haz_clic_tarjeta")}<FingerPointer
                                                            className="h-3 w-3 ml-2 fill-neutral-300" /></span>
                                                </div>

                                                <div
                                                    className="absolute w-full h-full leading-[260px] text-neutral-200 text-center font-bold text-5xl bg-gradient-to-r from-gradient-900 to-gradient-50 rounded-2xl p-10"
                                                    id="identity-card-back">
                                                    <div
                                                        className="h-full overflow-scroll md:overflow-hidden md:flex md:flex-row md:justify-center space-x-0 md:space-x-5 space-y-5 md:space-y-0">
                                                        <ul className="space-y-5 w-full md:w-1/2 flex flex-col p-2">
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <div
                                                                    className="font-bold text-xl md:text-2xl w-full xs:w-1/3 text-center xs:text-left">
                                                                    <span>
                                                                        {t("Educacion")}
                                                                    </span>
                                                                </div>
                                                                <ul className="w-full font-normal flex flex-col justify-center text-center xs:text-left">
                                                                    {fetchPioneer.data[0].attributes.education.map((educ, index) =>
                                                                        <li key={index}>
                                                                            <span className="text-base md:text-lg font-normal">{educ.university} ({educ.diploma})</span>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </li>
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <div
                                                                    className="font-bold text-xl md:text-2xl w-full xs:w-1/3 text-center xs:text-left">
                                                                    <span>
                                                                        {t("Ocupacion")}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="w-full font-normal flex flex-col justify-center text-center xs:text-left">
                                                                    <span className="text-base md:text-lg font-normal">
                                                                        {fetchPioneer.data[0].attributes.career.map((career, index) => {
                                                                            if (index < fetchPioneer.data[0].attributes.career.length - 2) {
                                                                                return (career.name + ", ")
                                                                            } else if (index === fetchPioneer.data[0].attributes.career.length - 2) {
                                                                                if (i18n.language === "es") {
                                                                                    return (career.name + " y ")
                                                                                }
                                                                                if (i18n.language === "en") {
                                                                                    return (career.name + " and ")
                                                                                }
                                                                                if (i18n.language === "fr") {
                                                                                    return (career.name + " et ")
                                                                                }
                                                                                if (i18n.language === "pt") {
                                                                                    return (career.name + " e ")
                                                                                }
                                                                                if (i18n.language === "it") {
                                                                                    return (career.name + " e ")
                                                                                }
                                                                                if (i18n.language === "de") {
                                                                                    return (career.name + " und ")
                                                                                }
                                                                                if (i18n.language === "ca") {
                                                                                    return (career.name + " i ")
                                                                                }

                                                                            } else {
                                                                                return (career.name)
                                                                            }
                                                                        }
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul className="space-y-5 w-full md:w-1/2 md:overflow-scroll my-5 p-2">
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <div
                                                                    className="font-bold text-xl md:text-2xl w-full xs:w-1/3 text-center xs:text-left">
                                                                    <span>
                                                                        {t("Area")}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="w-full font-normal flex flex-col justify-center text-center xs:text-left">
                                                                    <span className="text-base md:text-lg font-normal">
                                                                        {fetchPioneer.data[0].attributes.fields.data.map((field, index) => {
                                                                            const translatedName = translateField(field.attributes.value);
                                                                            if (index < fetchPioneer.data[0].attributes.fields.data.length - 2) {
                                                                                return (translatedName + ", ")
                                                                            } else if (index === fetchPioneer.data[0].attributes.fields.data.length - 2) {
                                                                                if (i18n.language === "es") {
                                                                                    return (translatedName + " y ")
                                                                                }
                                                                                if (i18n.language === "en") {
                                                                                    return (translatedName + " and ")
                                                                                }
                                                                                if (i18n.language === "fr") {
                                                                                    return (translatedName + " et ")
                                                                                }
                                                                                if (i18n.language === "pt") {
                                                                                    return (translatedName + " e ")
                                                                                }
                                                                                if (i18n.language === "it") {
                                                                                    return (translatedName + " e ")
                                                                                }
                                                                                if (i18n.language === "de") {
                                                                                    return (translatedName + " und ")
                                                                                }
                                                                                if (i18n.language === "ca") {
                                                                                    return (translatedName + " i ")
                                                                                }
                                                                            } else {
                                                                                return (translatedName)
                                                                            }
                                                                        })}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li className="flex flex-col items-center xs:items-start">
                                                                <div
                                                                    className="font-bold text-xl md:text-2xl w-full xs:w-1/3 text-center xs:text-left">
                                                                    <span>
                                                                        {t("Empleador")}
                                                                    </span>
                                                                </div>
                                                                <div className="w-full font-normal flex flex-col justify-center text-center xs:text-left">
                                                                    <ul className="space-y-0.5">
                                                                        {fetchPioneer.data[0].attributes.employer.map((employer, index) =>
                                                                            <li key={index}>
                                                                                <span className="text-base md:text-lg font-normal">{employer.name}</span>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <span className="absolute bottom-0 right-0 text-sm sm:text-base font-normal p-2 flex flex-row items-center text-neutral-300">{t("Haz_clic_tarjeta")}<FingerPointer className="h-3 w-3 ml-2 fill-neutral-300" /></span>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}

                            </div>
                        </div>

                    </section>

                    <section id="summary" className="w-full xl:w-1/2 flex flex-col">
                        {(() => {
                            if (fetchPioneer.loading) {
                                return (
                                    <div className="w-full space-y-3">
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-5" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-5" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-5" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-5" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-5" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-full rounded-full h-5" />
                                        <div className="animate-pulse bg-neutral-400 dark:bg-neutral-500 w-7/12 rounded-full h-5" />
                                    </div>
                                )
                            } else {
                                return (
                                    <span className="text-base md:text-lg text-justify h-full flex items-center">
                                        <ReactMarkdown className="markdown" linkTarget="_top">
                                            {fetchPioneer.data[0].attributes.shortDescription}
                                        </ReactMarkdown>
                                    </span>
                                )
                            }
                        })()}
                    </section>
                </div>
                {(() => {
                    if (fetchPioneer.data && fetchPioneer.data[0].attributes.comics.length > 0) {
                        const loaded = (e) => {

                            const items = fetchPioneer.data[0].attributes.comics.flatMap((story, indexStory) => (
                                [{
                                    "position": indexStory,
                                    "el": document.getElementById(`carousel-story-${indexStory}`)
                                }]
                            )).flat()

                            const options = {
                                activeItemPosition: 1,
                                interval: 3000,
                                onNext: () => { },
                                onPrev: () => { },
                                onChange: () => { }
                            };
                            const carousel = new Carousel(items, options);
                            const prevButton = document.getElementById('data-carousel-story-prev');
                            const nextButton = document.getElementById('data-carousel-story-next');

                            prevButton.addEventListener('click', () => {
                                carousel.prev();
                            });
                            nextButton.addEventListener('click', () => {
                                carousel.next();
                            });
                        }

                        return (
                            <section id="comics" className="flex flex-col items-center py-20">
                                <div className="relative w-full md:w-[calc(100%-5rem)] lg:w-[calc(100%-10rem)]">
                                    <div className="h-fit w-full">
                                        <div className="overflow-hidden relative aspect-w-16 aspect-h-9 mb-1" >
                                            {fetchPioneer.data[0].attributes.comics.map((story, indexStory) =>
                                                <div key={indexStory} id={"carousel-story-" + indexStory} className="hidden duration-700 ease-in-out h-fit" onLoad={loaded} >
                                                    <div className="aspect-w-16 aspect-h-9 z-5 mb-2">
                                                        <iframe title={story.title} src={youtubeLinkToEmbedded(story.url)} frameBorder="0" allow="" allowFullScreen />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <button id="data-carousel-story-prev" type="button" className="flex absolute h-fit top-[unset] -bottom-[40px] md:bottom-[unset] md:top-[calc(50%-1.5rem)] right-1/2 md:right-[unset] md:-left-24 z-30 justify-center items-end md:items-center px-4 h-full cursor-pointer">
                                        <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-neutral-800/50 dark:bg-neutral-200/50 hover:bg-neutral-800/70 dark:hover:bg-neutral-200/70">
                                            <ChevronLeft className="w-5 h-5 text-neutral-200 sm:w-6 sm:h-6 dark:text-neutral-800" />
                                            <span className="hidden">Previous</span>
                                        </span>
                                    </button>
                                    <button id="data-carousel-story-next" type="button" className="flex absolute h-fit top-[unset] -bottom-[40px] md:bottom-[unset] md:top-[calc(50%-1.5rem)] left-1/2 md:left-[unset] md:-right-24 z-30 justify-center items-end md:items-center px-4 h-full cursor-pointer">
                                        <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-neutral-800/50 dark:bg-neutral-200/50 hover:bg-neutral-800/70 dark:hover:bg-neutral-200/70">
                                            <ChevronRight className="w-5 h-5 text-neutral-200 sm:w-6 sm:h-6 dark:text-neutral-800" />
                                            <span className="hidden">Next</span>
                                        </span>
                                    </button>
                                </div>

                            </section>
                        )
                    }
                })()}

                <section id="timeline" className="py-20">
                    <div id="slider-step-progress" className="flex justify-between mb-[30px] ">
                        <div id="life" className="relative flex flex-col items-center flex-1 step-progress-item step-progress-item-completed">
                            <div className="relative z-5 rounded-full bg-neutral-400 mt-14 w-6 h-6 cursor-pointer" onClick={clickStep} />
                            <div className="h-fit w-fit absolute top-0 cursor-pointer duration-300 hover:scale-110" onClick={clickStep}>
                                <HeartPulse className="w-10 h-10 fill-neutral-400 pointer-events-none" />
                            </div>
                            <span className="absolute -bottom-8 cursor-pointer text-neutral-400 font-bold text-lg" onClick={clickStep}>{t("Vida")}</span>
                        </div>
                        <div id="contributions"
                            className="relative flex flex-col items-center flex-1 step-progress-item step-progress-item-uncompleted">
                            <div className="relative z-5 rounded-full bg-neutral-400 mt-14 w-6 h-6 cursor-pointer"
                                onClick={clickStep} />
                            <div className="h-fit w-fit absolute top-0 cursor-pointer duration-300 hover:scale-110"
                                onClick={clickStep}>
                                <OpenHand className="w-10 h-10 fill-neutral-400 pointer-events-none" />
                            </div>
                            <span className="absolute -bottom-[55px] md:-bottom-8 cursor-pointer text-neutral-400 font-bold text-lg" onClick={clickStep}>{t("Aportaciones")}</span>
                        </div>
                        <div id="acknowledgments"
                            className="relative flex flex-col items-center flex-1 step-progress-item step-progress-item-uncompleted">
                            <div className="relative z-5 rounded-full bg-neutral-400 mt-14 w-6 h-6 cursor-pointer"
                                onClick={clickStep} />
                            <div className="h-fit w-fit absolute top-0 cursor-pointer duration-300 hover:scale-110"
                                onClick={clickStep}>
                                <Handshake className="w-10 h-10 fill-neutral-400 pointer-events-none" />
                            </div>
                            <span className="absolute -bottom-8 cursor-pointer text-neutral-400 font-bold text-lg" onClick={clickStep}>{t("Reconocimientos")}</span>
                        </div>
                        <div id="quotes"
                            className="relative flex flex-col items-center flex-1 step-progress-item step-progress-item-uncompleted">
                            <div className="relative z-5 rounded-full bg-neutral-400 mt-14 w-6 h-6 cursor-pointer"
                                onClick={clickStep} />
                            <div className="h-fit w-fit absolute top-0 cursor-pointer duration-300 hover:scale-110"
                                onClick={clickStep}>
                                <Quotes className="w-10 h-10 fill-neutral-400 pointer-events-none" />
                            </div>
                            <span className="absolute -bottom-[55px] md:-bottom-8 cursor-pointer text-neutral-400 font-bold text-lg" onClick={clickStep}>{t("Citas")}</span>
                        </div>
                    </div>

                    <div id="slider-content-step-progress"
                        className="relative flex justify-center items-center lg:max-w-full lg:mx-auto max-h-fit overflow-hidden">
                        <div id="life-content"
                            className="h-fit flex flex-col justify-center w-full duration-[2000ms] ease-in-out inset-0 transition-all transform z-10 translate-x-0p">
                            <h5 className="mt-10 mb-10 pl-10 font-bold">{t("Vida")}</h5>
                            {(() => {
                                if (fetchPioneer.loading) {
                                    return (
                                        <div>LOADING...</div>
                                    )
                                } else {
                                    return (
                                        <ReactMarkdown className="markdown list-markdown" linkTarget="_blank">{fetchPioneer.data[0].attributes.life}</ReactMarkdown>
                                    )
                                }
                            })()}
                        </div>
                        <div id="contributions-content"
                            className="h-fit flex flex-col justify-center absolute w-full duration-[2000ms] ease-in-out inset-0 transition-all transform z-10 translate-x-100p">
                            <h5 className="mt-10 mb-10 pl-10 font-bold">{t("Aportaciones")}</h5>
                            {(() => {
                                if (fetchPioneer.loading) {
                                    return (
                                        <div>LOADING...</div>
                                    )
                                } else {
                                    return (
                                        <ReactMarkdown
                                            className="markdown list-markdown" linkTarget="_blank">{fetchPioneer.data[0].attributes.contribution}</ReactMarkdown>
                                    )
                                }
                            })()}
                        </div>
                        <div id="acknowledgments-content"
                            className="h-fit flex flex-col justify-center absolute w-full duration-[2000ms] ease-in-out inset-0 transition-all transform z-10 translate-x-200p">
                            <h5 className="mt-10 mb-10 pl-10 font-bold">{t("Reconocimientos")}</h5>
                            {(() => {
                                if (fetchPioneer.loading) {
                                    return (
                                        <div>LOADING...</div>
                                    )
                                } else {
                                    return (
                                        <ReactMarkdown
                                            className="markdown list-markdown" linkTarget="_blank">{fetchPioneer.data[0].attributes.acknowledgement}</ReactMarkdown>
                                    )
                                }
                            })()}
                        </div>
                        <div id="quotes-content"
                            className="h-fit flex flex-col justify-center absolute w-full duration-[2000ms] ease-in-out inset-0 transition-all transform z-10 translate-x-300p">
                            <h5 className="mt-10 mb-10 pl-10 font-bold">{t("Citas")}</h5>
                            {(() => {
                                if (fetchPioneer.loading) {
                                    return (
                                        <div>LOADING...</div>
                                    )
                                } else {
                                    return (
                                        <ReactMarkdown className="markdown space-y-5 list-markdown" linkTarget="_blank">{fetchPioneer.data[0].attributes.quotation}</ReactMarkdown>
                                    )
                                }
                            })()}
                        </div>
                    </div>

                    <div className="flex flex-row w-full justify-between mt-10">
                        <div
                            className="text-neutral-200 font-bold hover:scale-105 duration-200 cursor-pointer px-10 py-5 bg-gradient-to-br from-gradient-50 to-gradient-900 rounded-full"
                            onClick={previousStep}>
                            <span className="text-base xl:text-lg">{t("Anterior")}</span>
                        </div>
                        <div
                            className="text-neutral-200 font-bold hover:scale-105 duration-200 cursor-pointer px-10 py-5 bg-gradient-to-br from-gradient-50 to-gradient-900 rounded-full"
                            onClick={nextStep}>
                            <span className="text-base xl:text-lg">{t("Siguiente")}</span>
                        </div>
                    </div>
                </section>

                <section id="summary" className="py-20">
                    <h4 className="font-bold mb-10">{t("Fuentes_Documentales")}</h4>
                    {(() => {
                        if (fetchPioneer.loading) {
                            return (
                                <div>LOADING...</div>
                            )
                        } else {
                            return (
                                <ReactMarkdown className="markdown list-markdown space-y-3" linkTarget="_blank">{fetchPioneer.data[0].attributes.documentarySources}</ReactMarkdown>
                            )
                        }
                    })()}
                </section>

                <section id="comics" className="flex flex-col items-center py-20">
                    <h4 className="font-bold mb-10 w-full">{t("Material_Multimedia")}</h4>
                    <div className="relative w-full md:w-[calc(100%-5rem)] lg:w-[calc(100%-10rem)]">
                        {(() => {
                            if (fetchPioneer.loading) {
                                return (
                                    <div className="relative aspect-w-16 aspect-h-10 bg-neutral-700" />
                                )
                            } else {
                                const loaded = (e) => {

                                    const items = fetchPioneer.data[0].attributes.links.flatMap((media, indexMedia) => (
                                        [{
                                            "position": indexMedia,
                                            "el": document.getElementById(`carousel-media-${indexMedia}`)
                                        }]
                                    )).flat()
                                    const itemsTitle = fetchPioneer.data[0].attributes.links.flatMap((mediaTitle, indexMediaTitle) => (
                                        [{
                                            "position": indexMediaTitle,
                                            "el": document.getElementById(`carousel-media-title-${indexMediaTitle}`)
                                        }]
                                    )).flat()

                                    const options = {
                                        activeItemPosition: 1,
                                        interval: 3000,

                                        // callback functions
                                        onNext: () => { },
                                        onPrev: () => { },
                                        onChange: () => { }
                                    };
                                    const carousel = new Carousel(items, options);
                                    const carouselTitle = new Carousel(itemsTitle, options);
                                    const prevButton = document.getElementById('data-carousel-media-prev');
                                    const nextButton = document.getElementById('data-carousel-media-next');

                                    prevButton.addEventListener('click', () => {
                                        carousel.prev();
                                        carouselTitle.prev();
                                    });

                                    nextButton.addEventListener('click', () => {
                                        carousel.next();
                                        carouselTitle.next();
                                    });
                                }

                                return (
                                    <div className="h-fit w-full">
                                        <div className="overflow-hidden relative aspect-w-16 aspect-h-9 mb-1" >
                                            {fetchPioneer.data[0].attributes.links.map((media, indexMedia) =>
                                                <div key={indexMedia} id={"carousel-media-" + indexMedia} className="hidden duration-700 ease-in-out h-fit" onLoad={loaded} >
                                                    <div className="aspect-w-16 aspect-h-9 z-5 mb-2">
                                                        <iframe title={media.title} src={youtubeLinkToEmbedded(media.url)} frameBorder="0" allow="" allowFullScreen />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="relative">
                                            {fetchPioneer.data[0].attributes.links.map((mediaTitle, indexMediaTitle) =>
                                                <div key={indexMediaTitle} id={"carousel-media-title-" + indexMediaTitle} className="relative hidden duration-700 ease-in-out h-fit" onLoad={loaded} >
                                                    <ReactMarkdown className="markdown w-full" linkTarget="_blank">{mediaTitle.title}</ReactMarkdown>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })()}

                        <button id="data-carousel-media-prev" type="button" className="flex absolute h-fit top-[unset] -bottom-[40px] md:bottom-[unset] md:top-[calc(50%-1.5rem)] right-1/2 md:right-[unset] md:-left-24 z-30 justify-center items-end md:items-center px-4 h-full cursor-pointer">
                            <span
                                className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-neutral-800/50 dark:bg-neutral-200/50 hover:bg-neutral-800/70 dark:hover:bg-neutral-200/70">
                                <ChevronLeft className="w-5 h-5 text-neutral-200 sm:w-6 sm:h-6 dark:text-neutral-800" />
                                <span className="hidden">Previous</span>
                            </span>
                        </button>
                        <button id="data-carousel-media-next" type="button" className="flex absolute h-fit top-[unset] -bottom-[40px] md:bottom-[unset] md:top-[calc(50%-1.5rem)] left-1/2 md:left-[unset] md:-right-24 z-30 justify-center items-end md:items-center px-4 h-full cursor-pointer">
                            <span
                                className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-neutral-800/50 dark:bg-neutral-200/50 hover:bg-neutral-800/70 dark:hover:bg-neutral-200/70">
                                <ChevronRight className="w-5 h-5 text-neutral-200 sm:w-6 sm:h-6 dark:text-neutral-800" />
                                <span className="hidden">Next</span>
                            </span>
                        </button>
                    </div>

                </section>

            </div>
        </>
    );
}
