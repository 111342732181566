import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { Moon, Sun, Menu, Close, ChevronDownAccordion } from './icons/Icons';
import LanguageSelector from '../components/LanguageSelector';

const translations = {
  'Inteligencia Artificial': {
    'es': 'Inteligencia Artificial',
    'fr': 'Intelligence Artificielle',
    'ca': 'Intelligència Artificial',
    'it': 'Intelligenza Artificiale',
    'de': 'Künstliche Intelligenz',
    'en': 'Artificial Intelligence',
    'pt': 'Inteligência Artificial'
  }
};

const getTranslatedWord = (word, language) => {
  return translations[word] ? translations[word][language] || word : word;
};

export default function Header({ changeLanguage, theme, setTheme }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSubmenus, setActiveSubmenus] = useState({});
  const wrapperRef = useRef(null);
  const location = useLocation();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      changeBackground();
    };
  }, []);

  useEffect(() => {
    const themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
    const themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');
    const themeToggleBtn = document.getElementById('theme-toggle');

    // Set initial theme icons
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      themeToggleLightIcon.classList.remove('hidden');
      themeToggleDarkIcon.classList.add('hidden');
    } else {
      themeToggleDarkIcon.classList.remove('hidden');
      themeToggleLightIcon.classList.add('hidden');
    }

    const toggleTheme = () => {
      console.log('Toggle theme clicked');
      themeToggleDarkIcon.classList.toggle('hidden');
      themeToggleLightIcon.classList.toggle('hidden');
      if (localStorage.getItem('color-theme')) {
        if (localStorage.getItem('color-theme') === 'light') {
          document.documentElement.classList.add('dark');
          localStorage.setItem('color-theme', 'dark');
          setTheme("dark");
          console.log('Switched to dark mode');
        } else {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('color-theme', 'light');
          setTheme("light");
          console.log('Switched to light mode');
        }
      } else {
        if (document.documentElement.classList.contains('dark')) {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('color-theme', 'light');
          setTheme("light");
          console.log('Switched to light mode');
        } else {
          document.documentElement.classList.add('dark');
          localStorage.setItem('color-theme', 'dark');
          setTheme("dark");
          console.log('Switched to dark mode');
        }
      }
    };

    themeToggleBtn.addEventListener('click', toggleTheme);

    // Clean up the event listener on component unmount
    return () => {
      themeToggleBtn.removeEventListener('click', toggleTheme);
    };
  }, [setTheme]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && event.target.id !== 'close-menu') {
        setIsMobileMenuOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const links = document.querySelectorAll(".main-menu-item");
    links.forEach(link => {
      link.addEventListener("mouseover", () => {
        const submenu = link.lastChild;
        const title = link.firstChild;
        submenu.classList.remove("hidden");
        title.classList.add("main-menu-item--selected");
      });

      link.addEventListener("mouseleave", () => {
        links.forEach(linkToHide => {
          linkToHide.lastChild.classList.add("hidden");
          linkToHide.firstChild.classList.remove("main-menu-item--selected");
        });
      });
    });
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSubMenuToggle = (id) => {
    setActiveSubmenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const listMainMenuItems = [
    {
      title: t("Inicio"),
      link: `/`,
      menuDescription: "",
      menuCol: []
    },
    {
      title: t("Pioneras_informaticas"),
      link: `/${currentLanguage}/${t("pioneras_informaticas_url")}`,
      menuDescription: t("Pioneras_informaticas_descripcion"),
      menuCol: [
        {
          title: t("Areas"),
          list: [
            {
              title: t("Arquitectura_organizacion_computadoras"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("arquitectura-organizacion-computadoras")}`
            },
            {
              title: t("Ciencia_datos"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("ciencia-datos")}`
            },
            {
              title: t("Diseno_videojuegos"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("diseno-videojuegos")}`
            },
            {
              title: t("Graficos_computadora_visualizacion"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("graficos-computadora-visualizacion")}`
            },
            {
              title: t("Ingenieria_software"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("ingenieria-software")}`
            },
            {
              title: getTranslatedWord("Inteligencia Artificial", currentLanguage),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("inteligencia-artificial")}`
            },
            {
              title: t("Interfaz_computadora_humano"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("interfaz-computadora-humano")}`
            },
            {
              title: t("Lenguajes_programacion"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("lenguajes-programacion")}`
            },
            {
              title: t("Matematicas"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("matematicas")}`
            },
            {
              title: t("Redes_computadores"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("redes-computadores")}`
            },
            {
              title: t("Seguridad_informatica_criptografia"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}`,
              search: `?fields=${("seguridad-informatica-criptografia")}`
            },
          ]
        },
        {
          title: t("Categorias"),
          list: [
            {
              title: t("Todas_pioneras"),
              link: `${currentLanguage}/${t("pioneras_informaticas_url")}?tab=all`,
            },
            {
              title: t("Nacionalidades"),
              link: `${currentLanguage}/${t("categorias")}/${("nacionalidades")}`,
            },
            {
              title: t("Logros"),
              link: `${currentLanguage}/${t("categorias")}/${("logros")}`,
            },
            {
              title: t("Empresas"),
              link: `${currentLanguage}/${t("categorias")}/${t("empresas")}`,
            },
          ]
        },
      ]
    },
    {
      title: t("Eventos"),
      link: `/${currentLanguage}/${t("eventos_url")}`,
      menuDescription: t("Eventos_descripcion"),
      menuCol: [
        {
          title: t("Todo_sobre_los_eventos"),
          list: [
            {
              title: t("Solicita_exposicion"),
              link: `/${currentLanguage}/${t("eventos_url")}`,
              hash: "#requests-exhibition"
            },
            {
              title: t("Todo_sobre_la_exposicion"),
              link: `/${currentLanguage}/${t("eventos_url")}`,
              hash: "#all-about-exhibition"
            },
            {
              title: t("Todos_los_eventos"),
              link: `/${currentLanguage}/${t("eventos_url")}`,
              hash: "#all-events"
            },
          ]
        },
      ],
    },
    {
      title: t("Glosario"),
      link: `/${currentLanguage}/${t("glosario_url")}`,
      menuDescription: t("Glosario_descripcion"),
      menuCol: [],
    },
    {
      title: t("Libro"),
      link: `/${currentLanguage}/${t("libro_url")}`,
      menuDescription: t("Libro_descripcion"),
      menuCol: []
    },
    {
      title: t("Proyecto_cultural"),
      link: `/${currentLanguage}/${t("acerca_de_url")}`,
      menuDescription: t("Proyecto_cultural_descripcion"),
      menuCol: [
        {
          title: t("Todo_sobre_proyecto"),
          list: [
            {
              title: t("Acerca_de"),
              link: `/${currentLanguage}/${t("acerca_de_url")}`,
            },
            {
              title: t("Equipo_patrocinadores"),
              link: `/${currentLanguage}/${t("equipo_patrocinadores_url")}`,
            },
            {
              title: t("Medios_comunicacion"),
              link: `/${currentLanguage}/${t("medios_comunicacion_url")}`,
            },
            {
              title: t("Contacto"),
              link: `/${currentLanguage}/${t("contacto")}`,
            },
          ]
        },
      ],
    },
  ];

  return (
    <>
      <div className={`fixed w-full top-0 z-[999] h-[40px] px-4 sm:px-7 md:px-14 flex flex-row items-center bg-neutral-50/[.95] dark:bg-neutral-800/[.95] text-neutral-800 dark:text-neutral-200`}>
        <LanguageSelector />
      </div>
      <div id="menu" className={`fixed w-full top-[40px] z-[990] h-[80px] bg-neutral-50/[.95] dark:bg-neutral-800/[.95]`}>
        <div className={`flex justify-between h-full px-4 sm:px-7 md:px-10 font-bold flex items-center text-neutral-800 dark:text-neutral-200`}>
          <div className="flex items-center justify-start md:space-x-10 z-[995]">
            <Link to={`/${currentLanguage}/`} className="flex flex-row items-center">
              <span className="sr-only">Cyber-infos</span>
              <img
                className="h-10 w-auto sm:h-12"
                src={theme === "light" ? `${process.env.PUBLIC_URL}/logo_uja_light.png` : `${process.env.PUBLIC_URL}/logo_uja_dark.png`}
                alt="Logo of Pioneras Informaticas"
              />
            </Link>
          </div>
          <div className="flex flex-row justify-end">
            <div className="hidden xl:flex justify-end">
              <ul className="flex items-center justify-center xl:space-x-2 2xl:space-x-10 xl:mr-2 2xl:mr-10">
                {listMainMenuItems.map((item, id) => (
                  <li key={id} className={`main-menu-item text-neutral-800 dark:text-neutral-200`} id={id.toString()}>
                    <Link to={item.link} className={`xl:text-xl relative rounded-full z-[995] py-2 px-4 hover:bg-gradient-to-tr hover:from-gradient-50 hover:to-gradient-900 hover:text-neutral-100`}>
                      {item.title}
                    </Link>
                    <div className={`sub_menu absolute w-full left-0 -top-[40px] pt-[140px] z-[990] hidden backdrop-blur-custom`}>
                      {item.menuDescription !== "" || item.menuCol.length > 0 ? (
                        <div className="flex flex-row px-20 pt-5 pb-8 h-full w-full">
                          <div className={`w-2/5 pr-10 mr-10 border-r-2 border-neutral-800 dark:border-neutral-200`}>
                            <p className="italic font-normal">{item.menuDescription}</p>
                          </div>
                          <div className="w-3/5 flex flex-row space-x-10 justify-start">
                            {item.menuCol.map((col, id) => (
                              <div key={id} className="flex flex-col w-1/2">
                                <span className="mb-5">{col.title}</span>
                                <ul className="font-normal space-y-1">
                                  {col.list.map((el, id) => (
                                    <li key={id}>
                                      {el.hash ? (
                                        <Link to={{ pathname: el.link, hash: el.hash }} replace className="hover:underline text-xl">{el.title}</Link>
                                      ) : el.search ? (
                                        <Link to={{ pathname: el.link, search: el.search }} replace className="hover:underline text-xl">{el.title}</Link>
                                      ) : (
                                        <Link to={{ pathname: el.link }} replace className="hover:underline text-xl">{el.title}</Link>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-row">
              <div className="mr-1 sm:mr-4 xl:m-0 flex items-center">
                <button id="theme-toggle" className={`z-[999] group rounded-full p-2 inline-flex items-center justify-center hover:bg-neutral-400/[.2]`} data-current={theme}>
                  <Moon className="hidden w-7 h-7 fill-neutral-800 dark:fill-neutral-200" id="theme-toggle-dark-icon" />
                  <Sun className="hidden w-7 h-7 fill-neutral-800 dark:fill-neutral-200" id="theme-toggle-light-icon" />
                </button>
              </div>
              <div className="ml-1 sm:ml-4 xl:hidden flex items-center">
                <button onClick={handleMobileMenuToggle} id="button-menu" aria-expanded={isMobileMenuOpen} type="button" className={`mobile-menu group rounded-md p-2 inline-flex items-center justify-center ${isScrolled ? "hover:bg-neutral-400/[0.2]" : "hover:bg-neutral-100/[0.2] dark:hover:bg-neutral-600/[0.2]"}`}>
                  <span className="sr-only">Open main menu</span>
                  <Menu className={`w-7 h-7 fill-neutral-800 dark:fill-neutral-200 ${isMobileMenuOpen ? 'hidden' : 'block'}`} id="open-menu" />
                  <Close className={`w-7 h-7 fill-neutral-800 dark:fill-neutral-200 ${isMobileMenuOpen ? 'block' : 'hidden'}`} id="close-menu" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} ${(navigator.userAgent.indexOf("Safari") > -1) ? "pb-[100px]" : ""} xl:hidden p-5 transition-all duration-200 bg-neutral-200 dark:bg-neutral-800 shadow-xl absolute top-[100px] m-0 rounded-none w-full h-[calc(100vh-140px)] z-[1000] overflow-scroll`} id="mobile-menu" ref={wrapperRef}>
          <ul className="flex flex-col text-neutral-800 text-md font-medium">
            {listMainMenuItems.map((item, idItem) => (
              <li key={idItem} className="my-3">
                <h2 className="text-neutral-800 dark:text-neutral-200 bg-neutral-400 dark:bg-neutral-600 rounded-md mb-1">
                  <Link to={item.link} className={`flex justify-between items-center block py-2 pr-4 pl-3 w-full text-left font-medium`} aria-current={(location.pathname === item.link) ? "true" : "page"} aria-expanded="false" aria-controls={"accordion-main-body-" + idItem} onClick={() => setIsMobileMenuOpen(false)}>
                    <span>{item.title}</span>
                  </Link>
                </h2>
                {item.menuCol.length > 0 && (
                  <div className="rounded-md py-3 px-5 bg-neutral-300 dark:bg-neutral-700">
                    <ul className="flex flex-col text-neutral-800 text-md font-medium">
                      {item.menuCol.map((col, idCol) => (
                        <li key={idCol} className="my-2">
                          <h2 id={"accordion-sub-heading-" + (col.title).replace(/ /g, "_").toLowerCase()} className="text-neutral-800 dark:text-neutral-200 hover:text-neutral-200 hover:dark:text-neutral-800 hover:bg-neutral-600/[.95] hover:dark:bg-neutral-300/[.95] hover:rounded-md">
                            <button className={`flex justify-between items-center block py-2 pr-4 pl-3 w-full text-left font-medium`} aria-expanded="false" aria-controls={"accordion-sub-body-" + (col.title).replace(/ /g, "_").toLowerCase()} onClick={() => handleSubMenuToggle(`${idItem}-${idCol}`)}>
                              <span>{col.title}</span>
                              <ChevronDownAccordion className="w-6 h-6 shrink-0" />
                            </button>
                          </h2>
                          <div id={"accordion-sub-body-" + (col.title).replace(/ /g, "_").toLowerCase()} className={`transition-all duration-500 overflow-hidden ${activeSubmenus[`${idItem}-${idCol}`] ? 'block' : 'hidden'}`} aria-labelledby={"accordion-sub-heading-" + (col.title).replace(/ /g, "_").toLowerCase()}>
                            <ul className="rounded-b-md px-5 py-2 bg-neutral-300 dark:bg-neutral-600 text-neutral-200">
                              {col.list.map((el, id) => (
                                <li key={id} className="w-full my-2 hover:bg-neutral-400/50 rounded-md pl-3 py-2">
                                  {el.hash ? (
                                    <Link to={{ pathname: el.link, hash: el.hash }} replace className="text-neutral-700 dark:text-neutral-300 block w-full" onClick={() => setIsMobileMenuOpen(false)}>{el.title}</Link>
                                  ) : el.search ? (
                                    <Link to={{ pathname: el.link, search: el.search }} replace className="text-neutral-700 dark:text-neutral-300 block w-full" onClick={() => setIsMobileMenuOpen(false)}>{el.title}</Link>
                                  ) : (
                                    <Link to={{ pathname: el.link }} replace className="text-neutral-700 dark:text-neutral-300 block w-full" onClick={() => setIsMobileMenuOpen(false)}>{el.title}</Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
