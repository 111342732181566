import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HEADER_HEIGHT} from "../config";

export default function Error404() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, -HEADER_HEIGHT);
    }, [location]);
    const { t } = useTranslation();

    return (
        <div className="h-screen bg-neutral-200 dark:bg-neutral-800 flex justify-center items-center">
            <div className="flex flex-col space-y-10 items-center">
                <span className="error text-8xl text-neutral-800 dark:text-neutral-200 font-bold">Error 404</span>
                <Link to="/" className="text-4xl text-neutral-200 px-5 py-3 rounded-lg bg-gradient-to-br from-gradient-50 to-gradient-900 shadow-md hover:scale-105 transition-all duration-300">{t("Error404_enlace")}</Link>
            </div>
        </div>
    );
}