import { Helmet } from "react-helmet";
import React from "react";
import { WEBSITE_URL } from "../config";
import i18n from '../i18n';

export default function Seo({pageTitle, metaTitle, metaDescription, metaImage, keywords, metaRobots = "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", structuredData, metaViewport = "width=device-width, initial-scale=1", canonicalURL}) {

    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={metaDescription} key="description" />
            {/*<meta name="viewport" content={metaViewport} />*/}
            <link rel="canonical" href={WEBSITE_URL + canonicalURL} />

            <meta property="og:locale" content={i18n.language} key="og:locale" />
            <meta property="og:type" content="website" key="og:type" />
            <meta property="og:title" content={metaTitle} key="og:title" />
            <meta property="og:description" content={metaDescription} key="og:description" />
            <meta property="og:url" content={canonicalURL} key="og:url" />
            <meta property="og:site_name" content="Pioneras Informaticas" key="og:site_name" />
            <meta property="og:image" content={metaImage} key="og:image" />
            <meta property="og:image:secure_url" content={metaImage} key="og:image:secure_url" />
            <meta property="og:image:width" content="400" key="og:image:width" />
            <meta property="og:image:height" content="400" key="og:image:height" />
            <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
            <meta name="twitter:description" content={metaDescription} key="twitter:description" />
            <meta name="twitter:title" content={metaTitle} key="twitter:title" />
            <meta name="twitter:site" content="" key="twitter:site" />
            <meta name="twitter:image" content={metaImage} key="twitter:image" />
            <meta name="twitter:creator" content="" key="twitter:creator" />

            <meta name="robots" content={metaRobots} />
            <meta name="googlebot" content={metaRobots} />
            <meta name="googlebot-news" content={metaRobots} />

            <meta name="keywords" content={keywords} />

            <script type="application/ld+json">
                    {structuredData}
            </script>

        </Helmet>
    );
};