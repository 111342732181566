import React, {useEffect, useState, useRef} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import useFetch from "../hooks/useFetch";
import i18n from "../i18n";
import ReactMarkdown from "react-markdown";
import qs from "qs";
import {API_URL, HEADER_HEIGHT} from "../config";
import {ChevronLeft, ChevronRight, CloseCircle} from "../components/icons/Icons";
import Seo from "../components/Seo";

export default function Evento() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, -HEADER_HEIGHT);
    }, [location]);
    const { t } = useTranslation();
    const { slug } = useParams();
    const queryEvent = qs.stringify({
        filters: {
            slug: {
                $eq: slug,
            },
        },
        populate: {
            coverImage: {
                populate: '*'
            },
            images: {
                populate: '*'
            },
            event_type: {
                populate: '*'
            },
            seo: {
                populate: '*'
            },
            localizations: {
                populate: '*'
            },
        },
        sort: ['dateStart:desc', 'dateEnd:desc'],
    }, {
        encodeValuesOnly: true,
    });
    const fetchEvent = useFetch(`/api/events?${queryEvent}`, 0);

    const [showSlider, setShowSlider] = useState(false);
    const [mainMediaSlideshow, setMainMediaSlideshow] = useState(null);
    const handleClickMedia = (e) => {
        document.documentElement.classList.add("overflow-hidden");
        setShowSlider(true);
        setMainMediaSlideshow(fetchEvent.data[0].attributes.images.data.filter(media => media.attributes.hash === e.currentTarget.id)[0]);
    };
    const closeSlideShow = () => {
        document.documentElement.classList.remove("overflow-hidden");
        setShowSlider(false);
    };
    const nextSlideshowMedia = () => {
        const allMedia = fetchEvent.data[0].attributes.images.data;
        const indexCurrentMedia = allMedia.indexOf(mainMediaSlideshow);
        setMainMediaSlideshow(allMedia[(indexCurrentMedia + 1) % (allMedia.length)]);
    };
    const previousSlideshowMedia = () => {
        const allMedia = fetchEvent.data[0].attributes.images.data;
        const indexCurrentMedia = allMedia.indexOf(mainMediaSlideshow);
        const newIndex = (indexCurrentMedia - 1 < 0) ? allMedia.length - 1 : indexCurrentMedia - 1;
        setMainMediaSlideshow(allMedia[newIndex]);
    };

    const slideshowRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (slideshowRef.current && !slideshowRef.current.contains(event.target)) {
                closeSlideShow();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [slideshowRef]);

    function calculateDuration(timeEnd, timeStart) {
        const hour12 = i18n.language === "en";
        const startTime = timeStart.toLocaleString(i18n.language, { hour: 'numeric', minute: 'numeric', hour12: hour12 });
        const endTime = timeEnd.toLocaleString(i18n.language, { hour: 'numeric', minute: 'numeric', hour12: hour12 });
        let diff = (timeEnd.getTime() - timeStart.getTime()) / 1000;
        diff /= (60 * 60);
        const newTime = new Date(0,0);
        newTime.setSeconds(+diff * 60 * 60);
        return `${t("De")} ${startTime} ${t("a")} ${endTime} - ${t("duracion_de")} ${newTime.getHours() !== '00' ? newTime.getHours() : ""} ${t("horas")} ${t("y")} ${newTime.getMinutes() !== '00' ? newTime.getMinutes() : ""} ${t("minutos")}`;
    }

    const event_title = slug.split("-").slice(0, -1).join(" ").charAt(0).toUpperCase() + slug.split("-").slice(0, -1).join(" ").slice(1);

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${t("Eventos")} - ${fetchEvent.data && fetchEvent.data[0].attributes.seo ? fetchEvent.data[0].attributes.title : event_title}`}
                metaTitle={fetchEvent.data && fetchEvent.data[0].attributes.seo ? fetchEvent.data[0].attributes.seo.metaTitle : event_title}
                metaDescription={fetchEvent.data && fetchEvent.data[0].attributes.seo ? fetchEvent.data[0].attributes.seo.metaDescription : `Description of ${event_title}`}
                metaImage={fetchEvent.data && fetchEvent.data[0].attributes.seo ? (fetchEvent.data[0].attributes.seo.metaImage.data.attributes.formats.small ? fetchEvent.data[0].attributes.seo.metaImage.data.attributes.formats.small.url : fetchEvent.data[0].attributes.seo.metaImage.data.attributes.url) : ``}
                keywords={fetchEvent.data && fetchEvent.data[0].attributes.seo ? fetchEvent.data[0].attributes.seo.keywords : `${event_title}`}
                metaRobots={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/${t("eventos_url")}/${slug}`}
            />
            <div className="bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">

                <div className="relative h-96 w-full bg-gradient-to-r from-gradient-50/70 to-gradient-900/70 pt-20 flex items-center justify-center">
                    {(() => {
                        if (fetchEvent.loading) {
                            return (
                                <div></div>
                            )
                        } else if(fetchEvent.data && fetchEvent.data.length > 0) {
                            return(
                                <>
                                    <div className="z-5">
                                        <h1 className="text-center text-neutral-200 dark:text-neutral-200 font-bold mb-4">{fetchEvent.data[0].attributes.title}</h1>
                                        <h4 className="text-center text-neutral-200 dark:text-neutral-200">
                                            {t("Del")} {new Date(fetchEvent.data[0].attributes.dateStart).toLocaleDateString(i18n.language, {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                            })} {t("al")} {new Date(fetchEvent.data[0].attributes.dateEnd).toLocaleDateString(i18n.language, {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}
                                        </h4>
                                    </div>
                                    <img className="absolute w-full h-full z-0 top-0 left-0 object-cover brightness-50" src={fetchEvent.data[0].attributes.coverImage !== null ? API_URL + (fetchEvent.data[0].attributes.coverImage.data.attributes.formats.medium ? fetchEvent.data[0].attributes.coverImage.data.attributes.formats.medium.url : fetchEvent.data[0].attributes.coverImage.data.attributes.url) : "..."} alt={fetchEvent.data[0].attributes.coverImage !== null ? fetchEvent.data[0].attributes.coverImage.data.attributes.alternativeText : "..."}/>
                                </>
                            )}
                    })()}
                </div>
                <div className="">
                    {(() => {
                        if (fetchEvent.loading) {
                            return (
                                <div className="w-full flex items-center justify-center pt-20 pb-40">
                                    <svg role="status" className="w-16 h-16 mr-2 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                </div>
                            )
                        } else if(fetchEvent.data && fetchEvent.data.length > 0) {
                            return(
                                <>
                                    <section className="px-10 sm:px-16 md:px-24 lg:px-32 py-10">
                                        <h3 className="mb-4 font-bold">{t("Todo_sobre_este_evento")}</h3>

                                        <div className="bg-gradient-to-r from-gradient-50 to-gradient-900 w-full max-w-lg md:max-w-3xl mx-auto rounded-2xl text-neutral-200 p-10 my-10">
                                            <h4 className="w-full text-center mb-2 font-extrabold">{fetchEvent.data[0].attributes.title}</h4>
                                            <span className="block w-full text-center mb-8 text-base xs:text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl">{fetchEvent.data[0].attributes.event_type.data ? fetchEvent.data[0].attributes.event_type.data.attributes.name : ""}</span>
                                            <div className="space-y-4">
                                                <div className="text-base xs:text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl flex flex-col">
                                                    <span className="font-bold mr-4">
                                                        {t("Donde")}
                                                        <a href={fetchEvent.data[0].attributes.linkToInstitution !== null ? fetchEvent.data[0].attributes.linkToInstitution : "#"} target="_blank" rel="noreferrer" className="ml-4 text-neutral-50 font-bold hover:underline">{fetchEvent.data[0].attributes.institution}</a>
                                                    </span>
                                                    <span>
                                                        {fetchEvent.data[0].attributes.address}
                                                    </span>
                                                </div>
                                                <div className="text-base xs:text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl">
                                                <span className="font-bold mr-4">
                                                    {t("Cuando")}
                                                </span>
                                                    <span>
                                                    {t("Del")} {new Date(fetchEvent.data[0].attributes.dateStart).toLocaleDateString(i18n.language, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })} {t("al")} {new Date(fetchEvent.data[0].attributes.dateEnd).toLocaleDateString(i18n.language, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })}
                                                </span>
                                                </div>
                                                {(() => {
                                                    if (fetchEvent.data[0].attributes.event_type.data && fetchEvent.data[0].attributes.event_type.data.attributes.value === t("conferencia")) {
                                                        return (
                                                            <div className="text-base xs:text-base sm:text-lg md:text-lg lg:text-xl xl:text-xl">
                                                            <span className="font-bold mr-4">
                                                                {t("Horario")}
                                                            </span>
                                                                <span>
                                                                    {calculateDuration(new Date(fetchEvent.data[0].attributes.dateEnd),new Date(fetchEvent.data[0].attributes.dateStart))}
                                                                </span>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
<ReactMarkdown className="markdown" linkTarget="_blank">                                        
                                            {fetchEvent.data[0].attributes.description}
                                        </ReactMarkdown>
                                    </section>
                                    {(() => {
                                        if (fetchEvent.data[0].attributes.images.data && fetchEvent.data[0].attributes.images.data.length > 0) {
                                            return (
                                                <section className="px-10 sm:px-16 md:px-24 lg:px-32 py-10">
                                                    <div className="">
                                                        <ul className="flex flex-wrap gap-2 after:content-[''] after:grow-[999]">
                                                            {fetchEvent.data[0].attributes.images.data.map((media, index) => {
                                                                const isVideo = media.attributes.mime.startsWith('video');
                                                                return (
                                                                    <li key={index} className="h-80 cursor-pointer relative grow group overflow-hidden rounded-sm" onClick={handleClickMedia} id={media.attributes.hash}>
                                                                        {isVideo ? (
                                                                            <video className="object-cover w-full h-full align-middle rounded-sm group-hover:scale-105 duration-200 pointer-events-none" controls>
                                                                                <source src={API_URL + media.attributes.url} type={media.attributes.mime} />
                                                                            </video>
                                                                        ) : (
                                                                            <img className="object-cover w-full h-full align-middle rounded-sm group-hover:scale-105 duration-200 pointer-events-none" src={API_URL + (media.attributes.formats.medium ? media.attributes.formats.medium.url : media.attributes.url)} alt={media.attributes.alternativeText} />
                                                                        )}
                                                                        <div className="opacity-0 group-hover:opacity-100 duration-200 absolute bottom-0 left-0 w-full h-[100px] bg-gradient-to-t from-black/90 to-transparent z-3 rounded-b-sm flex justify-center items-end p-4 pointer-events-none">
                                                                            <span className="text-neutral-200 ">
                                                                                {media.attributes.caption}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </section>
                                            )
                                        }
                                    })()}
                                </>
                            )} else {
                            return (
                                <div className="py-20 px-10">
                                    {t("error_data")} <a href="mailto:pionerasinformaticas@ujaen.es" className="text-gradient-500 font-bold">pionerasinformaticas@ujaen.es</a>
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>
            {(() => {
                if (showSlider) {
                    const isVideo = mainMediaSlideshow.attributes.mime.startsWith('video');
                    return (
                        <div className="fixed h-full w-full top-0 bg-neutral-900/80 z-1000">
                            <div className="h-full w-full pt-16 px-20 grid items-center justify-center">
                                <div className="flex justify-center relative w-[85vw]" ref={slideshowRef}>
                                    {isVideo ? (
                                        <video className="h-full object-cover block max-h-[85vh] w-auto" controls>
                                            <source src={mainMediaSlideshow !== "" ? API_URL + mainMediaSlideshow.attributes.url : ""} type={mainMediaSlideshow.attributes.mime} />
                                        </video>
                                    ) : (
                                        <img id="0" className="h-full object-cover block max-h-[85vh] w-auto" src={mainMediaSlideshow !== "" ? API_URL + (mainMediaSlideshow.attributes.formats.medium ? mainMediaSlideshow.attributes.formats.medium.url : mainMediaSlideshow.attributes.url) : ""} alt={mainMediaSlideshow !== "" ? mainMediaSlideshow.attributes.alternativeText : ""} />
                                    )}
                                    <button className="absolute left-0 mx-5 top-[calc(50%-48px)] sm:top-[calc(50%-64px)] translate-y-1/2 transition-all duration-200 ease-in-out w-12 sm:w-16 h-12 sm:h-16 z-1050 bg-neutral-900/60 cursor-pointer rounded-full flex justify-center items-center hover:scale-125" aria-hidden="true" onClick={previousSlideshowMedia}>
                                        <ChevronLeft className="stroke-neutral-200 h-10 sm:h-12 w-10 sm:h-12" />
                                    </button>
                                    <button className="absolute right-0 mx-5 top-[calc(50%-48px)] sm:top-[calc(50%-64px)] translate-y-1/2 transition-all duration-200 ease-in-out w-12 sm:w-16 h-12 sm:h-16 z-1050 bg-neutral-900/60 cursor-pointer rounded-full flex justify-center items-center hover:scale-125" aria-hidden="true" onClick={nextSlideshowMedia}>
                                        <ChevronRight className="stroke-neutral-200 h-10 sm:h-12 w-10 sm:h-12" />
                                    </button>
                                </div>
                            </div>
                            <button className="absolute top-4 right-6 transition-all duration-700 hover:rotate-90 hover:scale-110" onClick={closeSlideShow}>
                                <CloseCircle className="fill-neutral-200 h-10 sm:h-16 w-10 sm:w-16"/>
                            </button>
                        </div>
                    )
                }
            })()}
        </>
    );
}
