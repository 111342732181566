import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import {useTranslation} from "react-i18next";
import i18n from "../i18n";

export default function Footer({theme}) {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <footer className="p-4 bg-neutral-200 sm:py-6 sm:px-10 lg:px-20 dark:bg-neutral-800">

            <div className="flex mt-2 space-x-6 sm:space-x-10 lg:space-x-16 justify-center">
                <a href="https://www.facebook.com/UJA.Cultura/" target="_blank" rel="noreferrer" className="hover:scale-125 duration-200">
                    <svg className="w-10 h-10" viewBox="0 0 24 24" aria-hidden="true">
                        <defs>
                            <linearGradient id="background-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" style={{stopColor: "rgba(var(--color-gradient-50),1)"}} />
                                <stop offset="100%" style={{stopColor: "rgba(var(--color-gradient-900),1)"}} />
                            </linearGradient>
                        </defs>
                        <g fill="url(#background-gradient)">
                            <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"/>
                        </g>
                    </svg>
                </a>
                <a href="https://www.instagram.com/uja.cultura/" target="_blank" rel="noreferrer" className="hover:scale-125 duration-200">
                    <svg className="w-10 h-10" viewBox="0 0 1024 1024" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="background-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" style={{stopColor: "rgba(var(--color-gradient-50),1)"}} />
                                <stop offset="100%" style={{stopColor: "rgba(var(--color-gradient-900),1)"}} />
                            </linearGradient>
                        </defs>
                        <g fill="url(#background-gradient)">
                            <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"/>
                        </g>
                    </svg>
                </a>
                <a href="https://twitter.com/vicproyec" target="_blank" rel="noreferrer"  className="hover:scale-125 duration-200">
                    <svg className="w-10 h-10" viewBox="0 0 24 24" aria-hidden="true">
                        <defs>
                            <linearGradient id="background-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" style={{stopColor: "rgba(var(--color-gradient-50),1)"}} />
                                <stop offset="100%" style={{stopColor: "rgba(var(--color-gradient-900),1)"}} />
                            </linearGradient>
                        </defs>
                        <g fill="url(#background-gradient)">
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                        </g>
                    </svg>
                </a>
                <a href="mailto:pionerasinformaticas@ujaen.es" className="hover:scale-125 duration-200">
                    <svg className="w-10 h-10" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="background-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" style={{stopColor: "rgba(var(--color-gradient-50),1)"}} />
                                <stop offset="100%" style={{stopColor: "rgba(var(--color-gradient-900),1)"}} />
                            </linearGradient>
                        </defs>
                        <g fill="url(#background-gradient)">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </g>
                    </svg>
                </a>
            </div>

            <hr className="my-8 border-gray-200 sm:mx-auto dark:border-gray-700 "/>

            <div className="flex flex-col md:flex-row space-y-5 space-x-0 md:space-y-0 md:space-x-10 justify-between">
                <div className="w-full md:w-2/6 mb-6 md:mb-0">
                    <Link to="" className="flex items-center">
                        <img src={theme === "light" ? `${process.env.PUBLIC_URL}/logo_uja_cultura_light_2.png` : `${process.env.PUBLIC_URL}/logo_uja_cultura_dark_2.png`} className="mr-3 h-24 border-0" alt="UJA Cultura UCC logo"/>
                    </Link>
                </div>
                <div className="w-full md:w-3/6 grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
                    <div>
                        <h2 className="mb-6 text-base font-semibold text-gray-900 uppercase dark:text-white">{t("Paginas")}</h2>
                        <ul className="text-gray-600 dark:text-gray-400 space-y-4">
                            <li>
                                <Link to={`/${currentLanguage}/${t("pioneras_informaticas_url")}`} className="hover:underline text-base md:text-lg">{t("Pioneras_informaticas")}</Link>
                            </li>
                            <li>
                                <Link to={`/${currentLanguage}/${t("eventos_url")}`} className="hover:underline text-base md:text-lg">{t("Eventos")}</Link>
                            </li>
                            <li>
                                <Link to={`/${currentLanguage}/${t("glosario_url")}`} className="hover:underline text-base md:text-lg">{t("Glosario")}</Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 className="mb-6 text-base font-semibold text-gray-900 uppercase dark:text-white">{t("Proyecto_cultural")}</h2>
                        <ul className="text-gray-600 dark:text-gray-400 space-y-4">
                            <li>
                                <Link to={`/${currentLanguage}/${t("acerca_de_url")}`} className="hover:underline text-base md:text-lg">{t("Acerca_de")}</Link>
                            </li>
                            <li>
                                <Link to={`/${currentLanguage}/${t("equipo_patrocinadores_url")}`} className="hover:underline text-base md:text-lg">{t("Equipo_patrocinadores")}</Link>
                            </li>
                            <li>
                                <Link to={`/${currentLanguage}/${t("medios_comunicacion_url")}`} className="hover:underline text-base md:text-lg">{t("Medios_comunicacion")}</Link>
                            </li>
                            <li>
                                <Link to={`/${currentLanguage}/${t("contacto")}`} className="hover:underline text-base md:text-lg">{t("Contacto")}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr className="my-8 border-gray-200 sm:mx-auto dark:border-gray-700 "/>

            <div className="flex flex-col-reverse items-center justify-between lg:flex-row">
                <span className="text-base text-neutral-600 dark:text-neutral-400 text-center sm:text-left lg:mr-5 mt-3 lg:mt-0">
                    © 2022 <a href="https://flowbite.com" className="hover:underline text-base">Pioneras Informaticas™</a>
                </span>
                <ul className="flex flex-wrap items-center justify-center sm:justify-end mt-3 text-neutral-600 dark:text-neutral-400 sm:mt-0">
                    <li>
                        <a href={`https://www.ujaen.es/${currentLanguage === "es" ? "politica-de-privacidad" : "en/privacy-policy" }`} target="_blank" rel="noreferrer" className="mx-1 hover:underline lg:mx-2 text-base">{t("Politica_privacidad")}</a>
                    </li>
                    |
                    <li>
                        <a href={`https://www.ujaen.es/${currentLanguage === "es" ? "politica-de-cookies" : "en/cookies-policy" }`} target="_blank" rel="noreferrer" className="mx-1 hover:underline lg:mx-2 text-base">{t("Politica_cookies")}</a>
                    </li>
                    |
                    <li>
                        <Link to={`/${currentLanguage}/${t("accesibilidad")}`} className="mx-1 hover:underline lg:mx-2 text-base">{t("Declaracion_accesibilidad")}</Link>
                    </li>
                </ul>

            </div>
        </footer>
    );
}
