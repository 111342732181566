import React, { useEffect } from 'react';
import { HeroHeader } from '../images/Illustrations';
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import i18n from '../i18n';
import Seo from '../components/Seo';
import { HEADER_HEIGHT } from "../config";

const youtubeLinkToEmbedded = (link) => {
    const url = new URL(link);
    if (url.pathname.split("/")[1] === "embed") {
        return link;
    } else if (url.href.indexOf("?v=") !== -1) {
        const urlParams = new URLSearchParams(url.search);
        return "https://www.youtube.com/embed/" + urlParams.get('v');
    } else {
        return "https://www.youtube.com/embed" + url.pathname;
    }
};

export default function Homepage() {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, -HEADER_HEIGHT);
    }, [location]);
    const { t } = useTranslation();

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${t("Inicio")}`}
                metaTitle={``}
                metaDescription={`Meta descripcion de la pagina inicio`}
                metaImage={``}
                keywords={``}
                metaRobots={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/`}
            />
            <div className="bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <div className="pt-[160px] w-full px-10 bg-gradient-to-r from-gradient-50/50 to-gradient-900/50">
                    <div className="relative flex flex-col lg:flex-row">
                        <div className="lg:w-1/2 flex flex-col justify-center mb-8 lg:mb-0">
                            <div className="mb-6 lg:mb-0 w-full">
                                <p className="inline-block ml-1 mb-4 text-xs font-semibold tracking-wider text-primary-700 dark:text-primary-500 uppercase">
                                </p>
                                <h1 className="mb-6 tracking-tight text-neutral-800 dark:text-neutral-50 font-bold sm:leading-none">
                                    {t("Pioneras_informaticas")}
                                </h1>
                                <p className="text-neutral-700 dark:text-neutral-300 mb-8 lg:mb-14">
                                    {t("hero_header_text")}
                                </p>
                                <Link to={`/${i18n.language}/${t("pioneras_informaticas_url")}`} className="mx-auto text-neutral-100 bg-gradient-700 hover:bg-gradient-700/80 focus:ring-4 focus:ring-gradient-300 font-medium rounded-lg px-5 py-2.5 mr-2 lg:mb-2 dark:bg-gradient-600 dark:hover:bg-gradient-700 focus:outline-none dark:focus:ring-gradient-800 duration-200">
                                    {t("Descubrir_pioneras")}
                                </Link>
                            </div>
                        </div>
                        <div className="lg:w-1/2 relative lg:ml-8">
                            <HeroHeader className="relative w-full bg-no-repeat object-contain max-h-96" />
                        </div>
                    </div>
                </div>
                <div className="h-16 md:h-36 overflow-hidden">
                    <svg viewBox="0 0 500 130" preserveAspectRatio="none" className="h-full w-full stroke-0" fill="url(#gradient)">
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" style={{ stopColor: "rgba(var(--color-gradient-50),.5)" }} />
                                <stop offset="100%" style={{ stopColor: "rgba(var(--color-gradient-900),.5)" }} />
                            </linearGradient>
                        </defs>
                        <path d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z" />
                    </svg>
                </div>
                <div className="pt-10 readspeaker-main">
                    <section className="section">
                        <h4 className="mb-4 font-bold">
                            {t("De_que_trata_este_sitio")}
                        </h4>
                        <div dangerouslySetInnerHTML={{ __html: t('falta_vocaciones_informaticas') }} />
                    </section>
                    <section className="section">
                        <h4 className="mb-4 font-bold">
                            {t("Vea_vídeo_presentacion")}
                        </h4>
                        <div className="w-full aspect-w-16 aspect-h-9">
                            <iframe title="TITLE" src={youtubeLinkToEmbedded("https://www.youtube.com/watch?v=qcxd580XJdE")} allow="" allowFullScreen />
                        </div>
                    </section>
                    <section className="section relative z-0">
                        <h4 className="mb-4 font-bold">
                            {t("De_opinion_sobre_este_sitio_web")}
                        </h4>
                        <div className="wrap-buttonToForm">
                            <a href="https://bit.ly/3sPC6e1" target="_blank" rel="noreferrer" className="buttonToForm-event">{t("Enviar_una_opinion")}</a>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}
