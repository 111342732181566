import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationFR from './locales/translationFR.json';
import translationEN from './locales/translationEN.json';
import translationES from './locales/translationES.json';
import translationPT from './locales/translationPT.json';
import translationIT from './locales/translationIT.json';
import translationDE from './locales/translationDE.json';
import translationCA from './locales/translationCA.json';

// the translations
const resources = {
    es: {
        translation: translationES
    },
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    pt: {
        translation : translationPT
    },
    it: {
        translation : translationIT
    },
    de: {
        translation: translationDE
    },
    ca: {
	translation: translationCA
    },
};

const userLang = navigator.language || navigator.userLanguage;
let language = userLang.substring(0,2);
if(!Object.keys(resources).includes(language)) language = 'es';

if(window.location.pathname.split("/")[1] === "en") {
    language = "en";
}else if(window.location.pathname.split("/")[1] === "fr") {
    language = "fr";
}else if(window.location.pathname.split("/")[1] === "pt"){
    language = "pt";
}else if(window.location.pathname.split("/")[1] === "it"){
    language = "it";
}else if(window.location.pathname.split("/")[1] === "de"){
    language = "de";
}else if(window.location.pathname.split("/")[1] === "ca") {
    language = "ca";
}else {
    language = "es";
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: language,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
