import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'flowbite';
import i18n from './i18n'; // Adjusted the import to align with typical project structure

// Use the createRoot method to manage the root of your React tree.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your application
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Remove the old ReactDOM.render method, it's no longer needed with the above setup
