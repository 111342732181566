import { useEffect, useState } from "react"
import { API_URL } from "../config";

const useFetch = (url, time = 1000) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            try {
                const res = await fetch(API_URL + url)
                const json = await res.json()

                setTimeout(() => {
                    setData(json.data);
                    setLoading(false)
                }, time)

            } catch (error) {
                setError(error)
                setLoading(false)
            }
        }

        fetchData();
    }, [url, time])

    return { loading, error, data }
}

export default useFetch