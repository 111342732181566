import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ChevronDown, Search } from "../components/icons/Icons";
import axios from "axios";
import { API_URL, HEADER_HEIGHT } from "../config";
import { useLocation } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import Seo from "../components/Seo";
import i18n from "../i18n";

export default function Glosario() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState([]);
    const [filterSearch, setFilterSearch] = useState("");
    const filterTagsRef = useRef(null);

    useEffect(() => {
        var hash = window.location.hash.substring(1);
        var element = document.getElementById(hash);
        if (element) {
            const elementPosition = element.offsetTop;
            window.scrollTo({
                top: elementPosition + HEADER_HEIGHT + 150,
                behavior: "smooth",
            });
        }
    }, []);

    useEffect(() => {
        if (location.hash === "") {
            window.scrollTo(0, -HEADER_HEIGHT);
        }
    }, [location]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (filterTagsRef.current && !filterTagsRef.current.contains(event.target) && !document.getElementById("dropdownFilterTags").classList.contains("hidden")) {
                document.getElementById("dropdownFilterTags").classList.add("hidden");
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterTagsRef]);

    useEffect(() => {
        const locale = i18n.language; // Obtenez la langue actuelle à partir d'i18n
        axios(`${API_URL}/api/glosarios?populate=tags&pagination[pageSize]=300&locale=${locale}`)
            .then((res) => {
                const sortedData = res.data.data.sort((a, b) => {
                    const isANumber = /^\d/.test(a.attributes.word);
                    const isBNumber = /^\d/.test(b.attributes.word);

                    if (isANumber && !isBNumber) {
                        return 1;
                    } else if (!isANumber && isBNumber) {
                        return -1;
                    } else {
                        return a.attributes.word.localeCompare(b.attributes.word);
                    }
                });
                setData(sortedData);
                const filterTags = new URL(window.location.href).searchParams.get("filtertags");
                if (filterTags !== null) {
                    setSearch(sortedData.filter((element) => (filterTags.split(",").every(tagURL => element.attributes.tags.data.map(tag => (tag.attributes.value)).flat().includes(tagURL)))));
                } else {
                    setSearch(sortedData);
                }
            })
            .catch((err) => console.log(err));
    }, [location, i18n.language]); // Re-run effect when location or language changes

    const addOneURLParameter = (parameterType, parameter) => {
        var newurl = "";
        if (window.location.search.includes(parameterType)) {
            let currentParams = new URL(window.location.href).searchParams.get(parameterType);
            newurl = window.location.href.replace((currentParams), new URL(window.location.href).searchParams.get(parameterType) + "," + parameter);
        } else {
            if (new URL(window.location.href).search === "") {
                newurl = window.location.href + '?' + parameterType + '=' + parameter;
            } else {
                newurl = window.location.href + '&' + parameterType + '=' + parameter;
            }
        }
        window.history.pushState({ path: newurl }, '', newurl);
    }

    const removeFilterFromURLParam = (parameterType, parameter) => {
        if (window.location.search.includes(parameterType)) {
            if (window.location.search.includes(parameter)) {
                let newurl = window.location.href.replace(("," + parameter), "").replace((parameter) + ",", "").replace(("&" + parameterType + "=" + parameter), "").replace(("?" + parameterType + "=" + parameter), "");
                window.history.pushState({ path: newurl }, '', newurl);
            }
        }
    }

    const handleChangeSearch = (e) => {
        setFilterSearch(e.target.value);
        setSearch(data.filter(filter => (filter.attributes.word.toLowerCase().includes(e.target.value.toLowerCase()))));
    }

    const handleChangeFilterTags = (e) => {
        if (e.target.checked) {
            addOneURLParameter("filtertags", e.target.value);
        } else {
            removeFilterFromURLParam("filtertags", e.target.value);
        }
        const allTagsFromURL = (new URL(window.location.href).searchParams.get("filtertags") === null) ? [] : new URL(window.location.href).searchParams.get("filtertags").split(",");
        setSearch(data.filter((element) => (allTagsFromURL.every(tagURL => element.attributes.tags.data.map(tag => (tag.attributes.value)).flat().includes(tagURL)) && (element.attributes.word.toLowerCase().includes(filterSearch.toLowerCase())))));
    }

    return (
        <>
            <Seo
                pageTitle={`Pioneras Informaticas - ${t("Glosario")}`}
                metaTitle={``}
                metaDescription={`Meta descripcion de la pagina Glosario`}
                metaImage={``}
                keywords={``}
                metaRobots={`index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
                structuredData={``}
                metaViewport={``}
                canonicalURL={`/${i18n.language}/${t("glosario_url")}`}
            />
            <div className="bg-neutral-50 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <div className="h-fit w-full bg-gradient-to-r from-gradient-50/50 to-gradient-900/50 mt-header-height py-4 px-4 4flex items-center justify-center">
                    <h1 className="text-center animation-shine font-bold leading-normal">{t("Glosario")}</h1>
                </div>
                <div className="py-10 text-neutral-900 dark:text-neutral-300 px-4 xs:px-10 sm:px-16 md:px-20">
                    <div className="py-2 md:py-4 px-0 sm:px-6 md:px-10 flex flex-col md:flex-row justify-between items-start md:items-end space-y-5 md:space-y-0">
                        <div className="h-full">
                            <label htmlFor="table-search" className="sr-only">{t("Busque")}</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <Search className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                                </div>
                                <input type="text" id="table-search" className="bg-neutral-50 border border-neutral-300 text-neutral-900 rounded-lg focus:ring-gradient-500 focus:border-gradient-500 block w-full xs:w-80 pl-10 p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-neutral-200 dark:focus:ring-gradient-500 dark:focus:border-gradient-500" placeholder={t("Buscar_una_palabra")} onChange={handleChangeSearch} />
                            </div>
                        </div>
                        <div className="relative h-full">
                            <button id="dropdownButtonFilterTags" className="text-base text-neutral-100 bg-gradient-600 hover:bg-gradient-600/80 focus:ring-4 focus:outline-none focus:ring-gradient-300 font-medium rounded-lg px-4 py-2.5 text-center inline-flex items-center dark:bg-gradient-600 dark:hover:bg-gradient-700 dark:focus:ring-gradient-800 flex items-center" type="button" onClick={() => document.getElementById("dropdownFilterTags").classList.toggle("hidden")}>
                                {t("Seleccione_etiquetas")}
                                <ChevronDown className="w-4 h-4 ml-2" />
                            </button>
                            <div id="dropdownFilterTags" ref={filterTagsRef} className="absolute m-0 top-14 right-unset md:right-0 z-[999] hidden bg-neutral-100 dark:bg-neutral-500 divide-y divide-neutral-100 rounded shadow w-max">
                                <ul className="py-3 space-y-6 pl-5 pr-10" aria-labelledby="dropdownButtonFilterTags">
                                    {(() => {
                                        if (data.length === 0) {
                                            return (
                                                <div className="w-full flex items-center justify-center">
                                                    <svg role="status" className="w-8 h-8 mr-2 text-neutral-200 animate-spin dark:text-neutral-600 fill-gradient-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                </div>
                                            );
                                        } else {
                                            const filterTags = data.map((word) =>
                                                (word.attributes.tags.data.map((tag) =>
                                                    ([{ name: tag.attributes.name, value: tag.attributes.value }])).flat()
                                                )).flat().filter((value, index, self) =>
                                                    index === self.findIndex((t) => (
                                                        t.name === value.name && t.value === value.value
                                                    ))
                                            );
                                            return (
                                                filterTags.map((tag, index) =>
                                                    <li key={index} className="flex items-center cursor-pointer">
                                                        <input id={`checkbok-tag-${index}`} type="checkbox" value={tag.value} className="cursor-pointer w-4 h-4 text-gradient-600 bg-gray-100 rounded border-gray-300 focus:ring-gradient-500 dark:focus:ring-gradient-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" onChange={handleChangeFilterTags} checked={(new URL(window.location).searchParams.get("filtertags") !== null) ? new URL(window.location).searchParams.get("filtertags").split(",").indexOf(tag.value) !== -1 : false} />
                                                        <label htmlFor={`checkbok-tag-${index}`} className="cursor-pointer ml-2 font-medium text-base text-gray-900 dark:text-gray-300">{tag.name}</label>
                                                    </li>
                                                )
                                            );
                                        }
                                    })()}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-scroll my-2 md:my-5">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg min-w-[800px]">
                            <table className="w-full text-left text-neutral-500 dark:text-neutral-400">
                                <thead className="text-neutral-700 uppercase bg-neutral-200 dark:bg-neutral-700 dark:text-neutral-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 w-[200px] max-w-[200px]">
                                            {t("Palabra")}
                                        </th>
                                        <th scope="col" className="px-6 py-3 min-w-[450px]">
                                            {t("Definicion")}
                                        </th>
                                        <th scope="col" className="px-6 py-3 min-w-[150px]">
                                            {t("Etiquetas")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-neutral-100/90 dark:bg-neutral-800/80">
                                    {(() => {
                                        if (search.length > 0) {
                                            return (
                                                search.map((item, indexItem) =>
                                                    <tr id={item.attributes.anchor} key={indexItem} className="border-b dark:border-neutral-700">
                                                        <th scope="row" className="px-6 py-4 font-medium text-neutral-900 dark:text-neutral-200 w-[200px] max-w-[200px]">
                                                            <span>{item.attributes.word}</span>
                                                        </th>
                                                        <td className="px-6 py-4 min-w-[450px]">
                                                            <ReactMarkdown className="markdown" linkTarget="_top">
                                                                {item.attributes.definition}
                                                            </ReactMarkdown>
                                                        </td>
                                                        <td className="px-6 py-4 min-w-[150px] flex flex-col space-y-2">
                                                            {item.attributes.tags.data.map((tag, indexTag) =>
                                                                <span key={indexTag} className="px-2 py-1 bg-gradient-600/80 dark:bg-gradient-600/60 border-2 border-gradient-700 rounded-md text-neutral-100 text-base font-bold text-center w-fit">{tag.attributes.name}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            );
                                        } else {
                                            return (
                                                <tr>
                                                    <td colSpan="3">
                                                        <span className="w-full flex items-center justify-center px-6 py-4 font-medium text-neutral-900 dark:text-neutral-200">{t("No_resultados")}</span>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
